import styled from "styled-components";

const BottomNavigationHeight = 54;

export const StyledBottomNavigationContainer = styled.div({
  position: "fixed",
  bottom: 0,
  width: "100%",
  display: "flex",
  justifyContent: "center",
});

export const StyledBottomNavigationBar = styled.div<{ $isActive?: boolean }>(
  ({ theme }) => ({
    display: "flex",
    justifyContent: "space-around",
    paddingInline: 20,
    height: BottomNavigationHeight,
    alignItems: "center",
    width: "100%",
    background: theme.common.white,
    borderTop: `1px solid ${theme.dark[500]}`,
  })
);

export const StyledNavigationIconContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  color: "green",
  fontSize: 10,
  fontWeight: 500,
  position: "relative",
  height: BottomNavigationHeight,
  gap: 3,
  width: 38,
  cursor: "pointer",
  "-webkit-tap-highlight-color": "transparent",
});
