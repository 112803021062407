import firebase from "firebase/compat/app";
import { getStorage, ref } from "firebase/storage";
import "firebase/compat/auth";
import "firebase/compat/firestore";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export const _fs = firebase.initializeApp(firebaseConfig).firestore();
export const getFirebaseStorageReference = (filePath: string) =>
  ref(getStorage(_fs.app), filePath); // get a specific file from storage
