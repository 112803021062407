import React from "react";
export const MenuIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_36_2311"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <path d="M0 0H20V20H0V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_36_2311)">
        <mask
          id="mask1_36_2311"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="20"
          height="4"
        >
          <path
            d="M18.3117 3.33333H1.68831C0.753242 3.33333 0 2.58974 0 1.66667C0 0.743592 0.75325 0 1.68831 0H18.3117C19.2467 0 20 0.743592 20 1.66667C20 2.58974 19.2467 3.33333 18.3117 3.33333Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_36_2311)">
          <mask
            id="mask2_36_2311"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="20"
            height="20"
          >
            <path d="M0 20H20V0H0V20Z" fill="white" />
          </mask>
          <g mask="url(#mask2_36_2311)">
            <path
              d="M-4.16748 -4.16797H24.1659V7.4987H-4.16748V-4.16797Z"
              fill="white"
            />
          </g>
        </g>
        <mask
          id="mask3_36_2311"
          maskUnits="userSpaceOnUse"
          x="0"
          y="16"
          width="20"
          height="4"
        >
          <path
            d="M18.3117 19.9984H1.68831C0.753242 19.9984 0 19.2548 0 18.3317C0 17.4086 0.75325 16.665 1.68831 16.665H18.3117C19.2467 16.665 20 17.4086 20 18.3317C20 19.2548 19.2467 19.9984 18.3117 19.9984Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask3_36_2311)">
          <mask
            id="mask4_36_2311"
            maskUnits="userSpaceOnUse"
            x="0"
            y="-1"
            width="20"
            height="21"
          >
            <path d="M0 19.9971H20V-0.00292969H0V19.9971Z" fill="white" />
          </mask>
          <g mask="url(#mask4_36_2311)">
            <path
              d="M-4.16748 12.4971H24.1659V24.1637H-4.16748V12.4971Z"
              fill="white"
            />
          </g>
        </g>
        <mask
          id="mask5_36_2311"
          maskUnits="userSpaceOnUse"
          x="0"
          y="8"
          width="20"
          height="4"
        >
          <path
            d="M18.3117 11.6683H1.68831C0.753242 11.6683 0 10.9247 0 10.0016C0 9.07855 0.75325 8.33496 1.68831 8.33496H18.3117C19.2467 8.33496 20 9.07855 20 10.0016C20 10.9247 19.2467 11.6683 18.3117 11.6683Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask5_36_2311)">
          <mask
            id="mask6_36_2311"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="20"
            height="21"
          >
            <path d="M0 20.0029H20V0.00292969H0V20.0029Z" fill="white" />
          </mask>
          <g mask="url(#mask6_36_2311)">
            <path
              d="M-4.16748 4.16992H24.1659V15.8366H-4.16748V4.16992Z"
              fill="white"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
