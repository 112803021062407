export const VisibilityOnIcon = () => {
  return (
    <svg
      width='25'
      height='20'
      viewBox='0 0 25 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.03125 19L21.7812 1'
        stroke='#222222'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.21191 17.0509C10.1947 17.3561 11.2188 17.5076 12.2479 17.4999C16.3479 17.5689 20.5059 14.6869 23.0719 11.8629C23.347 11.5575 23.4993 11.161 23.4993 10.7499C23.4993 10.3388 23.347 9.94231 23.0719 9.63688C22.1423 8.61709 21.1125 7.69344 19.9979 6.87988'
        stroke='#222222'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.6614 4.28195C13.8723 4.0856 13.0614 3.99083 12.2484 3.99995C8.21736 3.93295 4.04836 6.75195 1.42736 9.63495C1.15225 9.94038 1 10.3369 1 10.748C1 11.159 1.15225 11.5555 1.42736 11.861C2.22429 12.7333 3.0941 13.5363 4.02736 14.261'
        stroke='#222222'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.49805 10.75C8.49792 10.2575 8.59482 9.76981 8.78323 9.31478C8.97164 8.85975 9.24786 8.4463 9.5961 8.09806C9.94435 7.74981 10.3578 7.47359 10.8128 7.28518C11.2679 7.09678 11.7556 6.99987 12.248 7'
        stroke='#222222'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.998 10.749C15.9982 11.2416 15.9013 11.7293 15.7129 12.1844C15.5245 12.6395 15.2483 13.053 14.9001 13.4013C14.5518 13.7496 14.1384 14.026 13.6833 14.2145C13.2283 14.403 12.7406 14.5 12.248 14.5'
        stroke='#222222'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
