import { Modal as MuiModal, Box, useMediaQuery, useTheme } from "@mui/material";
import {
  modalStyles,
  StyledModalClose,
  StyledCustomContent,
  StyledModalHeaderContainer,
  StyledModalHeader,
  StyledModalBackButton,
} from "./AppModal.styles";
import { ModalProps } from "./AppModal.types";
import { CloseIcon } from "../../../assets/icons/CloseIcon";
import { BackButtonIcon } from "../AppBurgerMenu/icons/BackButtonIcon";

export const AppModal: React.FC<ModalProps> = ({
  isOpen,
  closeModal,
  header,
  modalCustomStyle,
  customContentStyle,
  children,
  addCloseIcon = true,
  disableBackdropClick = true,
  disableEscapeKeyDown = true,
  showBackArrow = false,
  currentTab,
  setCurrentTab,
  attrs,
  ...rest
}) => {
  const handleClose = (e?: any, reason?: any) => {
    if (reason === "backdropClick") return;
    closeModal();
  };
  const theme = useTheme();

  const isLgScreen = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <MuiModal
      open={isOpen}
      onClose={disableBackdropClick ? handleClose : closeModal}
      disableEscapeKeyDown={disableEscapeKeyDown}
      {...attrs?.modal}
    >
      <Box
        width={isLgScreen ? "98%" : "87%"}
        sx={{
          ...modalStyles,
          top: isLgScreen ? "3%" : "15%",
          bottom: isLgScreen ? undefined : 0,
          border: `5px solid ${theme.palette.grey[600]}`,
          borderRadius: isLgScreen ? "10px" : "10px 10px 10px 10px",
          maxHeight: isLgScreen ? "760px" : "60%",
        }}
        style={modalCustomStyle}
        {...rest}
      >
        {addCloseIcon && (
          <StyledModalClose onClick={closeModal}>
            <CloseIcon />
          </StyledModalClose>
        )}
        {!isLgScreen &&
          showBackArrow &&
          currentTab !== undefined &&
          currentTab > 0 && (
            <StyledModalBackButton
              onClick={() => setCurrentTab?.("", currentTab - 1)}
            >
              <BackButtonIcon />
            </StyledModalBackButton>
          )}
        {header && (
          <StyledModalHeaderContainer>
            <StyledModalHeader>{header}</StyledModalHeader>
          </StyledModalHeaderContainer>
        )}

        <StyledCustomContent style={customContentStyle} {...attrs?.footer}>
          {children}
        </StyledCustomContent>
      </Box>
    </MuiModal>
  );
};
