import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { APP_TOASTIFY } from "../../styles/styles.constants";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../redux/client/clientSlice";
import { getUser } from "../../redux/user/userSlice";

export const useRequestNotifications = () => {
  const [notificationPermission, setNotificationPermission] = useState<
    "default" | "granted" | "denied"
  >("default");

  const client = useSelector(getClientSelector);
  const user = useSelector(getUser);

  const requestNotificationPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      setNotificationPermission(permission);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (client.clientId && user.uid) {
      requestNotificationPermission();
    }
  }, [client.clientId, user.uid]);

  useEffect(() => {
    if (notificationPermission === "granted") {
      console.log("Notification permission granted");
      localStorage.setItem("notificationPermission", "granted");
    } else if (notificationPermission === "denied") {
      localStorage.setItem("notificationPermission", "denied");
      // toast("No notification access, click here to get notifications", {
      //   ...APP_TOASTIFY.ERROR,
      //   onClose: () => requestNotificationPermission(),
      // });
    }
  }, [notificationPermission]);

  return null;
};
