import styled from "styled-components";
import { TextField } from "@mui/material";

export const StyledTextField = styled(TextField)<{
  $styledErrorProp?: boolean;
}>(({ $styledErrorProp, theme }) => {
  return {
    width: "100%",

    "& input:-webkit-autofill": {
      "-webkit-box-shadow": `0 0 0 30px ${theme.common.white} inset !important`,
      "-webkit-text-fill-color": `${theme.dark[700]} !important`,
    },
    "& input:-webkit-autofill:hover": {
      "-webkit-box-shadow": `0 0 0 30px ${theme.common.white} inset !important`,
      "-webkit-text-fill-color": `${theme.dark[700]} !important`,
    },
    "& input:-webkit-autofill:focus": {
      "-webkit-box-shadow": `0 0 0 30px ${theme.common.white} inset !important`,
      "-webkit-text-fill-color": `${theme.dark[700]} !important`,
    },
    "& input:-webkit-autofill:active": {
      "-webkit-box-shadow": `0 0 0 30px ${theme.common.white} inset !important`,
      "-webkit-text-fill-color": `${theme.dark[700]} !important`,
    },

    "& .MuiInputBase-input": {
      color: theme.dark[700],
      paddingLeft: 25,
      fontWeight: 500,
    },

    "& .MuiInputBase-root": {
      height: "50px",
    },

    "& .MuiInputLabel-root": {
      color: $styledErrorProp ? theme.error.main : theme.dark[700],
      textIndent: 15,
      fontWeight: 500,
      fontSize: 12,
    },

    "& .MuiInputLabel-root.Mui-focused": {
      color: $styledErrorProp ? theme.error.main : theme.dark[400],
      fontWeight: 300,
    },

    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: `2px solid ${
          $styledErrorProp ? theme.error.main : theme.grey[600]
        }`,
        borderRadius: 50,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: `2px solid ${
          $styledErrorProp ? theme.error.main : theme.dark[400]
        }`,
        borderRadius: 50,
      },
    },
  };
});
