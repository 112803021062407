import { useTheme } from "@mui/material";

export const BackButtonIcon = () => {
  const theme = useTheme();
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="15"
        cy="15"
        r="15"
        fill={theme.palette.primary.main}
        fillOpacity="0.1"
      />
      <path
        d="M17.7927 7.16785L9.22552 14.4897C9.15423 14.556 9.09749 14.6357 9.05874 14.7238C9.01998 14.812 9 14.9068 9 15.0027C9 15.0985 9.01998 15.1933 9.05874 15.2815C9.09749 15.3696 9.15423 15.4493 9.22552 15.5156L17.7927 22.8341C17.8607 22.8927 17.9401 22.9373 18.0262 22.9655C18.1123 22.9937 18.2034 23.0048 18.294 22.9981C18.3846 22.9915 18.4729 22.9672 18.5537 22.9268C18.6344 22.8864 18.706 22.8306 18.7641 22.7628L19.8337 21.5296C19.9532 21.3921 20.0122 21.2145 19.9979 21.0352C19.9836 20.8558 19.8973 20.6891 19.7575 20.571L13.2635 15.1283C13.2448 15.1127 13.2298 15.0933 13.2195 15.0715C13.2091 15.0497 13.2038 15.026 13.2038 15.002C13.2038 14.978 13.2091 14.9543 13.2195 14.9325C13.2298 14.9107 13.2448 14.8913 13.2635 14.8757L19.7554 9.43093C19.825 9.37226 19.882 9.30085 19.9232 9.22079C19.9643 9.14072 19.9888 9.05357 19.9952 8.96432C20.0033 8.87556 19.993 8.78612 19.965 8.70128C19.9369 8.61644 19.8916 8.53792 19.8317 8.47037L18.7641 7.23917C18.7061 7.17099 18.6345 7.11485 18.5537 7.07408C18.4728 7.03331 18.3844 7.00875 18.2935 7.00187C18.2028 6.99519 18.1117 7.00646 18.0256 7.035C17.9395 7.06353 17.8603 7.10872 17.7927 7.16785Z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};
