import { MOBILE_TYPES } from "../types/mobile.types";

export const detectUserMobile = (): MOBILE_TYPES => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isIphone = /iphone/.test(userAgent);
  const isAndroid = /android/.test(userAgent);

  return {
    iphone: isIphone,
    android: isAndroid,
    unknown: !isIphone && !isAndroid,
  };
};
