import React from "react";
import { SwipeableDrawer } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  BurgerMenuWrapper,
  StyledCloseBurgerMenu,
  StyledMenuListWrapper,
  StyledMenuItem,
  StyledLogoContainer,
  StyledLogoImage,
  StyledMenuItemWrapper,
  StyledBurgerMenuFlexContainer,
  PAPER_PROPS_STYLES,
  StyledLogoAndCloseIconContainer,
} from "./AppBurgerMenu.styles";
import { useScreenWidth } from "../../../hooks/useScreenWidth/useScreenWidth";
import { getClientSelector } from "../../../redux/client/clientSlice";
import { APP_LINKS } from "./AppBurgerMenu.constant";
import { ArrowRightIcon } from "./icons/ArrowRightIcon";
import { APP_COMMON_COLORS } from "../../../providers/AppThemeProvider";
import { MenuIcon } from "./icons/MenuIcon";

export const AppBurgerMenu = () => {
  const [isOpenBurgerMenu, setIsOpenBurgerMenu] = useState<boolean>(false);
  const screenWidth = useScreenWidth();

  const location = useLocation();
  const pageName = location?.pathname?.split("/").pop();

  const navigate = useNavigate();

  const handleNavigation = (url: string) => {
    navigate(url);
    setIsOpenBurgerMenu(false);
  };

  const client = useSelector(getClientSelector);

  return (
    <div>
      {screenWidth <= 1200 && (
        <div onClick={() => setIsOpenBurgerMenu(true)}>
          <MenuIcon />
        </div>
      )}
      <SwipeableDrawer
        anchor={screenWidth <= 1200 ? "right" : "left"}
        open={screenWidth <= 1200 ? isOpenBurgerMenu : true}
        onClose={() => setIsOpenBurgerMenu(false)}
        onOpen={() => setIsOpenBurgerMenu(true)}
        variant={screenWidth <= 1200 ? undefined : "permanent"}
        PaperProps={
          screenWidth <= 1200
            ? PAPER_PROPS_STYLES.mobile
            : PAPER_PROPS_STYLES.desktop
        }
      >
        <BurgerMenuWrapper>
          <StyledLogoAndCloseIconContainer>
            <StyledLogoContainer>
              <StyledLogoImage src={client.branding.logoUrl} />
            </StyledLogoContainer>

            <StyledCloseBurgerMenu onClick={() => setIsOpenBurgerMenu(false)}>
              X
            </StyledCloseBurgerMenu>
          </StyledLogoAndCloseIconContainer>

          <StyledMenuListWrapper>
            {APP_LINKS.map((link, index) => {
              const isActive = Boolean(`/${pageName}` === link.url);
              return (
                link && (
                  <StyledMenuItemWrapper $isActive={isActive} key={index}>
                    <StyledMenuItem
                      $isActive={isActive}
                      onClick={() => handleNavigation(link.url)}
                      key={link.url}
                    >
                      <StyledBurgerMenuFlexContainer
                        style={{ gap: 10, alignItems: "center" }}
                      >
                        {link.icon(isActive)}
                        <div>{link.label}</div>
                      </StyledBurgerMenuFlexContainer>
                      <ArrowRightIcon isActive={isActive} />
                    </StyledMenuItem>
                  </StyledMenuItemWrapper>
                )
              );
            })}
          </StyledMenuListWrapper>
        </BurgerMenuWrapper>
      </SwipeableDrawer>
    </div>
  );
};
