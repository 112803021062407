import { useState } from "react";
import {
  Box,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AppModal } from "../../components/core/AppModal/AppModal";
import { AddIcon } from "../../assets/icons/AddIcon";
import { useAutoFill } from "../../hooks/useAutoFill/useAutoFill";
import { InitialMemberFormValues, addMemberTabs } from "./constants";
import { a11yProps } from "./TabPanel/TabPanel";
import { MemberFormValues, RolesAndGroups } from "./MembersPage.types";
import {
  StyledAddButton,
  StyledAddButtonContainer,
} from "./MembersPage.styles";
import { AddMemberTab } from "./Tabs/AddMemberTab/AddMemberTab";
import { GroupsTab } from "./Tabs/GroupsTab/GroupsTab";

export const MembersPage = () => {
  const theme = useTheme();
  const [disableTabs, setDisableTabs] = useState<boolean>(true);

  const { isAutoFill, autofillInputProps } = useAutoFill();
  const isLgScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const [addMemberFormValues, setAddMemebrsformValues] =
    useState<MemberFormValues>(InitialMemberFormValues);

  const [outerTabValue, setOuterTabValue] = useState(0);
  const [innerTabsValue, setInnerTabsValue] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddToAllChecked, setIsAddToAllChecked] = useState([false, false]);
  const [rolesAndGroups, setRolesAndGroups] = useState<RolesAndGroups>({});

  const handleOuterTabChange = (
    _: React.SyntheticEvent<Element, Event> | null,
    newValue: number
  ) => {
    setOuterTabValue(newValue);
  };

  const handleInnerTabChange = (
    _: React.SyntheticEvent<Element, Event> | null,
    newValue: number
  ) => {
    setInnerTabsValue(newValue);
  };

  const handleCloseModal = () => {
    setOuterTabValue(0);
    setInnerTabsValue(0);
    setRolesAndGroups({});
    setAddMemebrsformValues(InitialMemberFormValues);
    setIsModalOpen(false);
  };

  const handleOpenModal = () => setIsModalOpen(true);

  const handleAddToAll = (role: string, checkAll: boolean) => {
    setIsAddToAllChecked((prev) => {
      const newValue = [...prev];
      newValue[innerTabsValue] = checkAll;
      return newValue;
    });
    setRolesAndGroups((prev) => ({
      ...prev,
      [role]: prev[role]?.map((group) => ({
        ...group,
        isChecked: checkAll,
      })),
    }));
  };

  const toggleCheckBox = (
    _: React.SyntheticEvent<Element, Event> | null,
    role: string,
    index: number
  ) => {
    const updatedValues = rolesAndGroups[role].map((checkbox, i) => ({
      ...checkbox,
      isChecked: i === index ? !checkbox.isChecked : checkbox.isChecked,
    }));

    setIsAddToAllChecked((prev) => {
      const newValue = [...prev];
      newValue[innerTabsValue] = updatedValues.every(
        (value: any) => value.isChecked
      );
      return newValue;
    });
    setRolesAndGroups((prev) => ({
      ...prev,
      [role]: updatedValues,
    }));
  };

  return (
    <Box
      sx={{
        height: "100%",
        paddingX: "20px",
      }}
    >
      <StyledAddButtonContainer>
        <StyledAddButton onClick={handleOpenModal}>
          <AddIcon />
        </StyledAddButton>
      </StyledAddButtonContainer>

      <AppModal
        closeModal={handleCloseModal}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        isOpen={isModalOpen}
        modalCustomStyle={{
          minHeight: isLgScreen ? "93%" : "85%",
          maxHeight: isLgScreen ? "50vh" : "auto",
        }}
        showBackArrow
        currentTab={outerTabValue}
        setCurrentTab={handleOuterTabChange}
      >
        <Box
          display={"flex"}
          flexGrow={1}
          justifyContent={isLgScreen ? "flex-start" : "center"}
        >
          {isLgScreen && (
            <Grid width={"30%"}>
              <Typography fontWeight={500} variant="h6" mb={8}>
                Add Member
              </Typography>
              <Tabs
                orientation="vertical"
                TabIndicatorProps={{
                  style: {
                    display: "none",
                  },
                }}
                value={outerTabValue}
                onChange={handleOuterTabChange}
                aria-label="Vertical tabs example"
                sx={{
                  "& .MuiTabs-flexContainer": {
                    alignItems: "start",
                  },
                  "& button": {
                    fontWeight: 400,
                    fontSize: "14px",
                  },
                  "& .Mui-selected": {
                    color: theme.palette.primary.main,
                  },
                }}
              >
                {addMemberTabs.map((tab, index) => (
                  <Tab
                    key={index}
                    disableRipple
                    iconPosition="start"
                    icon={
                      <Box
                        sx={{
                          width: "30px",
                          height: "30px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "4px",
                          background:
                            outerTabValue === index
                              ? theme.palette.primary.main
                              : theme.palette.common.white,
                          border:
                            outerTabValue === index
                              ? `2px solid ${theme.palette.primary.main}`
                              : `2px solid ${theme.palette.primary.light}`,
                          color:
                            outerTabValue === index
                              ? theme.palette.common.white
                              : theme.palette.primary.light,
                        }}
                      >
                        {index + 1}
                      </Box>
                    }
                    sx={{
                      color:
                        outerTabValue === index
                          ? theme.palette.common.white
                          : theme.palette.primary.light,
                      gap: "2px",
                      textTransform: "none",
                    }}
                    label={tab}
                    disabled={disableTabs}
                    {...a11yProps(index)}
                  />
                ))}
              </Tabs>
            </Grid>
          )}

          <AddMemberTab
            outerTabValue={outerTabValue}
            addMemberTabs={addMemberTabs}
            isLgScreen={isLgScreen}
            handleOuterTabChange={handleOuterTabChange}
            setAddMemebrsformValues={setAddMemebrsformValues}
            isAutoFill={isAutoFill}
            autofillInputProps={autofillInputProps}
            hasError={(e: boolean) => setDisableTabs(e)}
          />

          <GroupsTab
            addMemberTabs={addMemberTabs}
            isLgScreen={isLgScreen}
            outerTabValue={outerTabValue}
            innerTabsValue={innerTabsValue}
            handleInnerTabChange={handleInnerTabChange}
            rolesAndGroups={rolesAndGroups}
            isAddToAllChecked={isAddToAllChecked}
            handleAddToAll={handleAddToAll}
            toggleCheckBox={toggleCheckBox}
            setRolesAndGroups={setRolesAndGroups}
            addMemberFormValues={addMemberFormValues}
            setIsModalClose={handleCloseModal}
          />
        </Box>
      </AppModal>
    </Box>
  );
};
