import { Box, styled } from "@mui/material";

export const StyledAddButtonContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const StyledAddButton = styled(Box)(({ theme }) => ({
  width: 50,
  height: 50,
  background: theme.palette.primary.main,
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
}));
