import { Box, Checkbox, Divider, List, styled } from "@mui/material";

export const StyledBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledList = styled(List)`
  overflow-y: auto;
  margin: 0;
  padding: 0;
  list-style: none;
  height: 200px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: ${(props) => props.theme.palette.common.white};
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.palette.primary.main};
    border-radius: 4px;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-right: 1;
`;

export const StyledDivider = styled(Divider)`
  border-style: dashed;
  border-width: 1px;
  border-spacing: 2px;
`;
