import { APP_COMMON_COLORS } from "../providers/AppThemeProvider";

export const APP_TOASTIFY = {
  SUCCESS: {
    hideProgressBar: true,
    type: "success" as any,
    icon: false,
    style: {
      background: APP_COMMON_COLORS.common.lightGreen,
      color: "white",
    },
  },
  ERROR: {
    hideProgressBar: true,
    type: "error" as any,
    icon: false,
    style: {
      background: APP_COMMON_COLORS.error.main,
      color: "white",
    },
  },
};
