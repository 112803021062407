import styled, { CSSObject } from "styled-components";
import { APP_COMMON_COLORS } from "../../../providers/AppThemeProvider";

export const BurgerMenuWidth = 220;

export const BurgerMenuWrapper = styled.div({
  padding: 20,
});

export const StyledMenuListWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  color: APP_COMMON_COLORS.dark[500],
  fontSize: 12,
  fontWeight: "400",
});

export const StyledMenuItem = styled.div<{ $isActive?: boolean }>(
  ({ $isActive, theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 10,
    borderBottom: `1px solid ${theme.dark[300]}`,
    padding: 10,
    userSelect: "none",
    ...($isActive && {
      background: theme.colorPrimary,
      color: APP_COMMON_COLORS.common.white,
      borderRadius: 2,
      borderColor: "transparent",
    }),
  })
);

export const StyledCloseBurgerMenu = styled.div({
  fontSize: 25,
  fontWeight: "bold",
  cursor: "pointer",
  "@media only screen and (min-width: 1200px)": {
    display: "none",
  },
});

export const StyledLogoAndCloseIconContainer = styled.div({
  display: "flex",
  justifyContent: "space-between",
  height: 79,
  borderBottom: `1px solid ${APP_COMMON_COLORS.dark[300]}`,
});

export const StyledLogoContainer = styled.div<{
  $customstyles?: CSSObject;
}>(({ $customstyles, theme }) => ({
  width: 60,
  height: 60,
  borderRadius: "50%",
  marginBottom: 18,
  ...$customstyles,
}));

export const StyledLogoImage = styled.img({
  display: "block",
  width: 60,
  cursor: "pointer",
});

export const StyledMenuItemWrapper = styled.div<{ $isActive?: boolean }>(
  ({ $isActive, theme }) => ({
    ":hover": {
      cursor: "pointer",
      color: $isActive ? APP_COMMON_COLORS.common.white : theme.colorSecondary,
      fontWeight: $isActive ? "inherit" : "bold",
    },
  })
);

export const StyledBurgerMenuFlexContainer = styled.div({
  display: "flex",
  justifyContent: "space-between",
});

export const PAPER_PROPS_STYLES = {
  mobile: {
    sx: {
      width: BurgerMenuWidth,
      background: `${APP_COMMON_COLORS.dark[200]}`,
    },
  },
  desktop: {
    sx: {
      marginTop: 0,
      width: BurgerMenuWidth,
      border: "none",
      background: `${APP_COMMON_COLORS.dark[200]}`,
    },
  },
};
