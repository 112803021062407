import { Avatar } from "@mui/material";
import styled from "styled-components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useSelector } from "react-redux";
import { getUser } from "../../../redux/user/userSlice";

const StyledProfileAvatarContainer = styled.div({
  display: "flex",
  alignItems: "center",
});

export const StyledArrowDown = styled(KeyboardArrowDownIcon)<{
  $isActive?: boolean;
}>(({ $isActive, theme }) => ({
  color: theme.colorPrimary,
}));

export const ProfileAvatar = () => {
  const user = useSelector(getUser);
  return (
    <StyledProfileAvatarContainer>
      <Avatar src={user.profilePictureUrl} />
      <StyledArrowDown />
    </StyledProfileAvatarContainer>
  );
};
