import { COLLECTIONS_TYPE } from "../constants/firebase.constants";
import { getFirstValueFromFirebaseByColKeyVal } from "../hooks/firebase/getFirstValueFromFirebaseByColKeyVal";
import { CLIENT_STATE } from "../types/client/client.types";

export const getClientIdFromDomain = async () => {
  const domain = String(localStorage.getItem("client"));

  const clientData = (await getFirstValueFromFirebaseByColKeyVal(
    COLLECTIONS_TYPE.clients,
    "domain",
    String(domain)
  )) as CLIENT_STATE;

  return clientData?.clientId;
};
