import { COLLECTIONS_TYPE } from "../../constants/firebase.constants";
import { getFirebaseDataByColKeyVal } from "./getFirebaseDataByColKeyVal";

export const getFirstValueFromFirebaseByColKeyVal = async (
  collection: COLLECTIONS_TYPE,
  key: string,
  value: string
) => {
  try {
    const data = await getFirebaseDataByColKeyVal(collection, key, value);
    if (data) {
      const firstDataObject = Object.values(data)[0];
      return firstDataObject;
    } else {
      return;
    }
  } catch (error) {
    console.log(error);
  }
};
