import { Switch, styled } from "@mui/material";

export const AppSwitch = styled(Switch)(({ theme }) => {
  return {
    padding: 8,
    width: "66px",
    height: "41px",
    "& .Mui-checked": {
      transform: "translateX(24px) !important",
      color: "white !important",
    },
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: theme.palette.primary.main,
      opacity: "1 !important",
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: "#E8E6E6",
      borderRadius: 25 / 2,
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 19,
        height: 19,
      },
    },
    "& .MuiSwitch-thumb": {
      opacity: 1,
      boxShadow: "none",
      width: 19,
      height: 19,
      margin: "2px 2px",
    },
  };
});
