import React from "react";
import { APP_COMMON_COLORS } from "../../../../providers/AppThemeProvider";

export const BrandingIcon = (props: { isActive: boolean }) => {
  const color = props.isActive
    ? APP_COMMON_COLORS.common.white
    : APP_COMMON_COLORS.dark[500];

  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2231 10.5487C18.1501 10.4214 18.0448 10.3157 17.9179 10.2421C17.791 10.1686 17.6469 10.1299 17.5002 10.1299C17.3535 10.1299 17.2094 10.1686 17.0825 10.2421C16.9556 10.3157 16.8503 10.4214 16.7774 10.5487C15.9658 11.9644 15 13.8375 15 14.7216C15 15.3846 15.2634 16.0205 15.7322 16.4893C16.201 16.9581 16.8368 17.2214 17.4998 17.2214C18.1628 17.2214 18.7986 16.9581 19.2674 16.4893C19.7362 16.0205 19.9996 15.3846 19.9996 14.7216C19.9996 13.8392 19.0338 11.9652 18.2231 10.5487Z"
        fill={color}
      />
      <path
        d="M15.4533 7.54415C15.507 7.39016 15.5107 7.22314 15.4639 7.06692C15.417 6.9107 15.3221 6.77326 15.1925 6.67422L8.87133 0.353067C8.63296 0.1264 8.31659 0 7.98765 0C7.65872 0 7.34235 0.1264 7.10398 0.353067L5.50077 1.95377C5.47716 1.97751 5.44816 1.99521 5.41625 2.00536C5.38434 2.01552 5.35045 2.01783 5.31746 2.0121L1.81107 1.39298C1.70139 1.36902 1.588 1.36748 1.4777 1.38843C1.36741 1.40939 1.26249 1.45242 1.16924 1.51494C1.07599 1.57746 0.996331 1.65818 0.935054 1.75225C0.873776 1.84632 0.83214 1.9518 0.812646 2.06236C0.793151 2.17293 0.7962 2.28629 0.821609 2.39564C0.847019 2.505 0.894264 2.60809 0.96051 2.69873C1.02675 2.78937 1.11063 2.86569 1.20711 2.92311C1.30359 2.98052 1.41067 3.01785 1.52193 3.03285L8.13806 4.19942C8.19211 4.20955 8.23996 4.24064 8.27118 4.2859C8.3024 4.33117 8.31445 4.38695 8.30471 4.44107L8.18555 5.11435C8.17543 5.16839 8.14434 5.21625 8.09907 5.24746C8.0538 5.27868 7.99803 5.29074 7.94391 5.281L3.1443 4.43773C3.1113 4.43194 3.0774 4.43421 3.04547 4.44437C3.01355 4.45453 2.98456 4.47226 2.96098 4.49606L0.610339 6.8467C0.416841 7.04015 0.263347 7.26982 0.158624 7.5226C0.0539008 7.77538 0 8.04631 0 8.31992C0 8.59353 0.0539008 8.86446 0.158624 9.11724C0.263347 9.37001 0.416841 9.59968 0.610339 9.79313L4.50086 13.6853C4.89151 14.0758 5.42127 14.2952 5.97365 14.2952C6.52603 14.2952 7.0558 14.0758 7.44645 13.6853L12.6927 8.44407C13.0079 8.17024 13.4036 8.00619 13.8201 7.97661H14.6534C14.8152 7.99775 14.9796 7.96734 15.1232 7.88971C15.2668 7.81207 15.3823 7.69116 15.4533 7.54415Z"
        fill={color}
      />
    </svg>
  );
};
