import React from "react";
import { APP_COMMON_COLORS } from "../../../../providers/AppThemeProvider";

export const ArrowRightIcon = (props: { isActive: boolean }) => {
  const color = props.isActive ? APP_COMMON_COLORS.common.white : "transparent";

  return (
    <svg
      width="25"
      height="10"
      viewBox="0 0 25 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.5 4.75H1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.75 8.5L23.5 4.75L19.75 1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
