import { IPHONE_BROWSER_TYPES } from "../types/mobile.types";

export const detectIphoneBrowser = (): IPHONE_BROWSER_TYPES => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isChrome = /iphone/.test(userAgent) && /crios/.test(userAgent);
  const isSafari =
    /iphone/.test(userAgent) &&
    /safari/.test(userAgent) &&
    !/crios/.test(userAgent);

  return {
    chrome: isChrome,
    safari: isSafari,
  };
};
