export const AppleIcon = () => (
  <svg
    width='25'
    height='25'
    viewBox='0 0 25 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_20_4008)'>
      <path
        d='M17.1864 0C15.8536 0.092187 14.2958 0.945307 13.388 2.05624C12.5599 3.06404 11.8786 4.56091 12.1443 6.01559C13.6005 6.0609 15.1052 5.18747 15.977 4.05779C16.7927 3.00623 17.4098 1.51874 17.1864 0Z'
        fill='black'
      />
      <path
        d='M22.4538 8.38749C21.1741 6.78281 19.3757 5.85156 17.6773 5.85156C15.4351 5.85156 14.4866 6.92499 12.9288 6.92499C11.3226 6.92499 10.1023 5.85469 8.16324 5.85469C6.25857 5.85469 4.23045 7.01874 2.94452 9.00936C1.13672 11.8125 1.4461 17.0827 4.37577 21.5718C5.4242 23.178 6.82419 24.9843 8.65543 24.9999C10.2851 25.0155 10.7445 23.9546 12.9523 23.9436C15.1601 23.9311 15.5788 25.014 17.2054 24.9968C19.0382 24.9827 20.5147 22.9812 21.5632 21.3749C22.3147 20.2234 22.5944 19.6437 23.1772 18.3437C18.9382 16.7296 18.2585 10.7015 22.4538 8.38749Z'
        fill='black'
      />
    </g>
    <defs>
      <clipPath id='clip0_20_4008'>
        <rect width='25' height='25' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
