import ReactDOM from "react-dom/client";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import { AppWrapper } from "./App";
import reportWebVitals from "./reportWebVitals";
import { registerServiceWorker } from "./registerServiceWorker.js";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<AppWrapper />);

registerServiceWorker();
reportWebVitals();
