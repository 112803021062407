import { configureStore } from "@reduxjs/toolkit";
import { userSlice } from "./index";
import { clientSlice } from "./client/clientSlice";

export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    client: clientSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
