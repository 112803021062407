import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { APP_ROUTES } from "../../constants/routes.constant";
import { useTheme } from "styled-components";
import { APP_COMMON_COLORS } from "../../providers/AppThemeProvider";

const PAGES_WITH_BODY_BACKGROUNF_COLOR_PRIMARY = [APP_ROUTES.signIn];

export const useSetBodyStyles = () => {
  const location = useLocation();
  const theme = useTheme();
  useEffect(() => {
    if (PAGES_WITH_BODY_BACKGROUNF_COLOR_PRIMARY.includes(location.pathname)) {
      document.body.style.backgroundColor = theme.colorPrimary;
    } else {
      document.body.style.backgroundColor = APP_COMMON_COLORS.common.white;
    }
  }, [location.pathname]);
};
