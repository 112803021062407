import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { getClientSelector } from "../redux/client/clientSlice";
import { AppSpinner } from "../components/core/AppSpinner/AppSpinner";
import { useGetClientByDomain } from "../hooks/useGetClientByDomain/useGetClientByDomain";

let theme = createTheme({
  palette: {
    secondary: {
      main: "#ba68c8",
      light: "#CCCCCC",
      contrastText: "#444444",
      200: "#F8F8F8",
      // 300: "#CCCCCC",
      400: "#666666",
      500: "#999999",
      600: "#444444",
      700: "#222222",
    },
    error: {
      main: "#EF6337",
    },
    common: {
      white: "#ffffff",
      black: "#000000",
    },
    grey: {
      "100": "#DDDDDD",
      300: "#E8E6E6",
      "600": "#D2CECE",
    },
    success: {
      main: "#00E651",
      light: "#00E676",
    },
  },
  typography: {
    fontFamily: '"Outfit", sans-serif',
    h4: {
      fontSize: "20px",
      "@media (min-width:1200px)": {
        fontSize: "40px",
      },
    },
    h5: {
      fontSize: "20px",
      "@media (min-width:1200px)": {
        fontSize: "30px",
      },
    },
  },
  // components: {
  //   MuiTab: {
  //     styleOverrides: {
  //       selected: {
  //         background: "red",
  //         color: "yellow",
  //       },
  //       disabled: {
  //         background: "yellow",
  //         color: "red",
  //       },
  //       textColorPrimary: "",
  //     },
  //   },
  // },
});

export const MuiThemeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  useGetClientByDomain(String(localStorage.getItem("client")));

  const { branding } = useSelector(getClientSelector) ?? {};

  const colorPrimary = branding?.colorPrimary;
  const colorSecondary = branding?.colorSecondary;
  if (!colorPrimary || !colorSecondary) return <AppSpinner />;

  console.log({ theme });

  theme = {
    ...theme,
    palette: {
      ...theme.palette,
      primary: {
        ...theme.palette.primary,
        main: colorPrimary,
        light: colorSecondary,
      },
    },
  };

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
