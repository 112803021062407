import { useState } from "react";

export const useAutoFill = () => {
  const [isAutoFill, setIsAutoFill] = useState(false);

  const handleAnimationStart = (e: React.AnimationEvent<HTMLDivElement>) => {
    if (e.animationName === "mui-auto-fill") {
      setIsAutoFill(true);
    }
  };

  const handleAnimationEnd = (e: React.AnimationEvent<HTMLDivElement>) => {
    if (e.animationName === "mui-auto-fill-cancel") {
      setIsAutoFill(false);
    }
  };

  const handleFocus = () => {
    setIsAutoFill(false);
  };

  return {
    isAutoFill,
    autofillInputProps: {
      onAnimationStart: handleAnimationStart,
      onAnimationEnd: handleAnimationEnd,
      onFocus: handleFocus,
    },
  };
};
