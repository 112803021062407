import styled from "styled-components";
import { APP_COMMON_COLORS } from "../../../providers/AppThemeProvider";

export const StyledProgressBar = styled.div<{
  color: string;
  strength?: "Weak" | "Medium" | "Strong";
}>(({ color, strength }) => ({
  width: 40,
  height: 6,
  borderTopLeftRadius: strength === "Weak" ? 10 : 0,
  borderBottomLeftRadius: strength === "Weak" ? 10 : 0,
  borderTopRightRadius: strength === "Strong" ? 10 : 0,
  borderBottomRightRadius: strength === "Strong" ? 10 : 0,
  background: APP_COMMON_COLORS.grey[300],
  backgroundColor: color,
}));
export const StyledStrengthWrapper = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 5,
});
export const StyledAppPasswordStrengthBarWrapper = styled.div({
  width: 100,
  display: "flex",
  flexDirection: "column",
  gap: 6,
});
export const StyledProgressBarWrapper = styled.div({
  display: "flex",
  flexDirection: "row",
  gap: 2,
});
