import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { COLLECTIONS_TYPE } from "../../constants/firebase.constants";
import { getFirebaseDataByColKeyVal } from "../firebase/getFirebaseDataByColKeyVal";
import { toast } from "react-toastify";
import { APP_TOASTIFY } from "../../styles/styles.constants";
import { setClient } from "../../redux/client/clientSlice";
import { CLIENT_STATE } from "../../types/client/client.types";

export const useGetClientByDomain = (domain: string) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchFirebaseColors = async () => {
      try {
        const res = await getFirebaseDataByColKeyVal(
          COLLECTIONS_TYPE.clients,
          "domain",
          domain
        );

        const clientData = Object.values(res)[0] as CLIENT_STATE;

        dispatch(setClient({ ...clientData }));
      } catch (err) {
        toast("Something went wrong", APP_TOASTIFY.ERROR);
      }
    };

    fetchFirebaseColors();
  }, [domain, dispatch]);

  return null;
};
