import * as Yup from "yup";

export const addMemberYupSchema = Yup.object({
  email: Yup.string()
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address")
    .required("Required")
    .min(5, "Minimum length is 5")
    .max(255, "Maximum length is 255"),

  firstName: Yup.string()
    .min(3, "Must be 5 characters or more")
    .required("Required"),
  lastName: Yup.string()
    .min(3, "Must be 5 characters or more")
    .required("Required"),
});

export const InitialMemberFormValues = {
  email: "",
  firstName: "",
  lastName: "",
};

export const addMemberTabs = ["Members Profile", "Groups"];
