import React from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import {
  StyledBottomNavigationContainer,
  StyledBottomNavigationBar,
  StyledNavigationIconContainer,
} from "./AppBottomNavigation.styles";
import { NAVIGATION_ITEMS } from "./AppBottomNavigation.constant";
import { isRunningAsPWA } from "../../../helpers/isRunningAsPWA";

export const AppBottomNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pageName = location?.pathname?.split("/").pop();

  if (!isRunningAsPWA()) {
    return <></>;
  }

  return (
    <StyledBottomNavigationContainer>
      <StyledBottomNavigationBar>
        {NAVIGATION_ITEMS.map((item, index) => {
          const isActive = Boolean(`/${pageName}` === item.route);

          return (
            <StyledNavigationIconContainer
              key={index}
              onClick={() => navigate(item.route)}
            >
              {item.icon(isActive)}
            </StyledNavigationIconContainer>
          );
        })}
      </StyledBottomNavigationBar>
    </StyledBottomNavigationContainer>
  );
};
