import { Box, SxProps, styled, useMediaQuery } from "@mui/material";
import { APP_COMMON_COLORS } from "../../../providers/AppThemeProvider";

export const modalStyles: SxProps = {
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  left: "50%",
  transform: "translate(-50%, 0%)",
  backgroundColor: APP_COMMON_COLORS.common.white,
  zIndex: 2,
  overflow: "auto",
  maxHeight: "90%",
  outline: "none",
  borderRadius: "14px",
  boxShadow: 24,
  border: "10px solid #808393",
  "::-webkit-scrollbar": {
    width: "7px",
    height: "7px",
  },
  "::-webkit-scrollbar-track": {
    background: "transparent",
  },
  "::-webkit-scrollbar-thumb": {
    background: "#00000033",
    borderRadius: "7px",
  },
};

export const StyledModalClose = styled(Box)(({ theme }) => ({
  backgroundColor: "rgba(128, 131, 147, 0.3)",
  cursor: "pointer",
  color: theme.palette.primary.main,
  position: "absolute",
  right: 20,
  top: 20,
  borderRadius: "50%",
  width: "30px",
  height: "30px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 100,
  marginLeft: "auto",
}));

export const StyledModalBackButton = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  color: theme.palette.primary.main,
  position: "absolute",
  left: 20,
  top: 20,
  borderRadius: "50%",
  width: "30px",
  height: "30px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 100,
}));

export const StyledCustomContent = styled(Box)(({ theme }) => {
  const isLgScreen = useMediaQuery(theme.breakpoints.up("lg"));
  return {
    padding: isLgScreen ? "80px 40px" : "20px",
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
  };
});

export const StyledModalHeaderContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "sticky",
  top: 0,
  padding: "16px 0",
  zIndex: 2,
});

export const StyledModalHeader = styled(Box)({
  color: APP_COMMON_COLORS.dark[700],
  fontWeight: 600,
  fontSize: 20,
});
