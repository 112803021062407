import React from "react";
import { APP_COMMON_COLORS } from "../../../../providers/AppThemeProvider";
import { useTheme } from "styled-components";

export const MembersNavIcon = (props: { isActive: boolean }) => {
  const theme = useTheme();

  const color = props.isActive
    ? theme.colorPrimary
    : APP_COMMON_COLORS.dark[500];

  return (
    <svg
      width="20"
      height="15"
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4578 3.95835C11.4579 4.76562 11.2687 5.56167 10.9053 6.28253C10.8665 6.36145 10.8537 6.45064 10.8686 6.53731C10.8836 6.62398 10.9256 6.70369 10.9887 6.76503C11.7203 7.49908 12.7131 7.91321 13.7495 7.9167C14.7993 7.9167 15.8061 7.49966 16.5485 6.75733C17.2908 6.01499 17.7079 5.00817 17.7079 3.95835C17.7079 2.90853 17.2908 1.90171 16.5485 1.15937C15.8061 0.417039 14.7993 1.56435e-08 13.7495 0C12.7117 0.00238649 11.7172 0.416602 10.9845 1.15167C10.9215 1.21301 10.8794 1.29272 10.8645 1.37939C10.8495 1.46606 10.8623 1.55525 10.9012 1.63417C11.266 2.35468 11.4567 3.15074 11.4578 3.95835Z"
        fill={color}
      />
      <path
        d="M13.75 8.33203C13.0762 8.33226 12.4071 8.44289 11.7691 8.65953C11.7022 8.68186 11.6421 8.72081 11.5943 8.77273C11.5466 8.82465 11.5128 8.88783 11.4962 8.95637C11.4795 9.02491 11.4805 9.09655 11.4991 9.16458C11.5177 9.23262 11.5533 9.29482 11.6024 9.34537C12.978 10.7413 13.7493 12.6223 13.75 14.5821C13.75 14.6926 13.7939 14.7985 13.872 14.8767C13.9501 14.9548 14.0561 14.9987 14.1666 14.9987H19.5833C19.6938 14.9987 19.7998 14.9548 19.8779 14.8767C19.9561 14.7985 20 14.6926 20 14.5821C19.9982 12.925 19.3392 11.3363 18.1674 10.1646C16.9957 8.99285 15.407 8.3338 13.75 8.33203Z"
        fill={color}
      />
      <path
        d="M6.25132 7.9167C8.43746 7.9167 10.2097 6.14449 10.2097 3.95835C10.2097 1.77221 8.43746 0 6.25132 0C4.06518 0 2.29297 1.77221 2.29297 3.95835C2.29297 6.14449 4.06518 7.9167 6.25132 7.9167Z"
        fill={color}
      />
      <path
        d="M12.5001 14.5821C12.5001 12.9244 11.8416 11.3347 10.6695 10.1626C9.49736 8.99051 7.90764 8.33203 6.25003 8.33203C4.59242 8.33203 3.0027 8.99051 1.83059 10.1626C0.658483 11.3347 0 12.9244 0 14.5821C0 14.6926 0.0438989 14.7985 0.122039 14.8767C0.20018 14.9548 0.306161 14.9987 0.416668 14.9987H12.0834C12.1939 14.9987 12.2999 14.9548 12.378 14.8767C12.4562 14.7985 12.5001 14.6926 12.5001 14.5821Z"
        fill={color}
      />
    </svg>
  );
};
