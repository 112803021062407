import React from "react";

import { DashIcon } from "../../core/AppBurgerMenu/icons/DashIcon";
import {
  StyledAddToHomeScreenContainer,
  StyledInstallingGuidance,
} from "./PWAInstallingInfoModal.styles";
import IosShareIcon from "@mui/icons-material/IosShare";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { IPHONE_BROWSER_TYPES } from "../../../types/mobile.types";
import { detectIphoneBrowser } from "../../../helpers/detectIphoneBrowser";

export const IphoneView = () => {
  const iphoneBrowser: IPHONE_BROWSER_TYPES = detectIphoneBrowser();

  return (
    <>
      <DashIcon />
      <StyledInstallingGuidance>
        Click the
        <IosShareIcon
          style={{ fontSize: 25, marginInline: 5, paddingBottom: 5 }}
        />
        {iphoneBrowser.safari && "icon below"}
        {iphoneBrowser.chrome && "icon above"}
      </StyledInstallingGuidance>
      <DashIcon />
      <div>Scroll down and then click: </div>
      <StyledAddToHomeScreenContainer>
        <div style={{ paddingLeft: 10 }}>Add to Home Screen</div>
        <AddBoxOutlinedIcon />
      </StyledAddToHomeScreenContainer>
    </>
  );
};
