import { Checkbox, styled } from "@mui/material";

export const AppCheckBox = styled(Checkbox)(({ theme }) => ({
  width: 30,
  height: 30,

  color: `${theme.palette.primary.light} !important`,
  fontSize: 30,
  "&.Mui-checked": {
    // color: theme.palette.primary.main,
    color: "white",
    borderColor: "red",
    "& .MuiSvgIcon-root": {
      fill: theme.palette.primary.main,
    },
  },
}));
