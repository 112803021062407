import React from "react";
import { ThemeProvider } from "styled-components";
import { useSelector } from "react-redux";
import { useGetClientByDomain } from "../hooks/useGetClientByDomain/useGetClientByDomain";
import { getClientSelector } from "../redux/client/clientSlice";
import { AppSpinner } from "../components/core/AppSpinner/AppSpinner";

export type AppCommonColors = typeof APP_COMMON_COLORS;

export const APP_COMMON_COLORS = {
  dark: {
    200: "#F8F8F8",
    300: "#CCCCCC",
    400: "#666666",
    500: "#999999",
    600: "#444444",
    700: "#222222",
  },
  grey: {
    300: "#E8E6E6",
    600: "#D2CECE",
  },
  common: {
    white: "#ffffff",
    black: "#000000",
    lightGreen: "#00E676",
    darkGreen: "#11B76A",
  },
  error: {
    main: "#EF6337",
  },
};

export const AppThemeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  useGetClientByDomain(String(localStorage.getItem("client")));

  const { branding } = useSelector(getClientSelector) ?? {};

  const colorPrimary = branding?.colorPrimary;
  const colorSecondary = branding?.colorSecondary;

  if (!colorPrimary || !colorSecondary) return <AppSpinner />;

  document.body.style.backgroundColor = colorPrimary;

  document
    .querySelector("meta[name='theme-color']")
    ?.setAttribute("content", colorPrimary);

  return (
    <ThemeProvider
      theme={{ ...APP_COMMON_COLORS, colorPrimary, colorSecondary }}
    >
      {children}
    </ThemeProvider>
  );
};
