export const VisibilityOffIcon = () => {
  return (
    <svg
      width='25'
      height='16'
      viewBox='0 0 25 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.2484 1.00121C8.21736 0.933207 4.04836 3.75021 1.42736 6.63521C1.15225 6.94064 1 7.33714 1 7.74821C1 8.15927 1.15225 8.55578 1.42736 8.86121C3.99136 11.6852 8.14836 14.5672 12.2484 14.4982C16.3484 14.5672 20.5064 11.6852 23.0724 8.86121C23.3475 8.55578 23.4997 8.15927 23.4997 7.74821C23.4997 7.33714 23.3475 6.94064 23.0724 6.63521C20.4484 3.75021 16.2794 0.933207 12.2484 1.00121Z'
        stroke='#222222'
        strokeWidth='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M15.998 7.75027C15.9979 8.49191 15.7777 9.21683 15.3656 9.83338C14.9534 10.4499 14.3677 10.9304 13.6824 11.2141C12.9972 11.4978 12.2432 11.5719 11.5158 11.4271C10.7885 11.2823 10.1204 10.9251 9.59604 10.4006C9.0717 9.87608 8.71464 9.20789 8.57003 8.48049C8.42542 7.75309 8.49974 6.99914 8.7836 6.31397C9.06745 5.62881 9.5481 5.0432 10.1648 4.63119C10.7814 4.21918 11.5064 3.99927 12.248 3.99927C12.7406 3.99914 13.2284 4.09608 13.6835 4.28455C14.1386 4.47302 14.5521 4.74933 14.9003 5.09768C15.2486 5.44603 15.5248 5.8596 15.7132 6.31474C15.9015 6.76989 15.9983 7.25769 15.998 7.75027Z'
        stroke='#222222'
        strokeWidth='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
