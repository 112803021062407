import { TabPanelProps } from "../MembersPage.types";

export function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{
        maxWidth: "570px",
        width: "100%",
        display: value === index ? "flex" : "none",
        flexDirection: "column",
      }}
    >
      {children}
    </div>
  );
};
