import React from "react";

import {
  Navigate,
  Route,
  HashRouter as Router,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { SignInPage } from "./pages/SignInPage/SignInPage";
import { Provider, useDispatch, useSelector } from "react-redux";
import { store } from "./redux/store";
import { AppThemeProvider } from "./providers/AppThemeProvider";
import { MuiThemeProvider } from "./providers/MuiThemeProvider";
import { useEffect } from "react";
import { firebaseConfig } from "./services/firebase/config";
import {
  isUserLoggedInSelector,
  logoutAndRemoveUserData,
  setUserData,
  setUserIsLoggedIn,
} from "./redux/user/userSlice";
import { GlobalState } from "./types/global";
import { AppSpinner } from "./components/core/AppSpinner/AppSpinner";
import { MembersPage } from "./pages/MembersPage/MembersPage";
import { ToastContainer, toast } from "react-toastify";
import { APP_ROUTES } from "./constants/routes.constant";
import { getFirstValueFromFirebaseByColKeyVal } from "./hooks/firebase/getFirstValueFromFirebaseByColKeyVal";
import { COLLECTIONS_TYPE } from "./constants/firebase.constants";
import { USER_STATE } from "./types/user/user.types";
import { getClientIdFromDomain } from "./helpers/getClientIdFromDomain";
import { isUserWithClientId } from "./helpers/isUserWithClientId";
import { APP_TOASTIFY } from "./styles/styles.constants";
import { AppHeader } from "./components/core/AppHeader/AppHeader";
import { DashboardPage } from "./pages/DashboardPage/DashboardPage";
import { TemplatesPage } from "./pages/TemplatesPage/TemplatesPage";
import { BrandingPage } from "./pages/BrandingPage/BrandingPage";
import { StyledAppLoggedInContainer } from "./App.styles";
import { useSetBodyStyles } from "./hooks/useSetBodyStyles/useSetBodyStyles";
import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
import { useSubsrcibeToInitialTopics } from "./hooks/firebase/useSubsrcibeToInitialTopics";
import { useRequestNotifications } from "./hooks/firebase/useRequestNotifications";
import { PWAInstallingInfoModal } from "./components/shared/PWAInstallingInfoModal/PWAInstallingInfoModal";
import { SignUpPage } from "./pages/SignUpPage/SignUpPage";
import { AppBottomNavigation } from "./components/core/AppBottomNavigation/AppBottomNavigation";

export const App = () => {
  useSetBodyStyles();
  useRequestNotifications();
  useSubsrcibeToInitialTopics();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  const isLoggedIn = useSelector((state: GlobalState) =>
    isUserLoggedInSelector(state)
  );

  // To handle rendering sign up page while navigating
  useEffect(() => {
    if (pathname === APP_ROUTES.signUp) {
      dispatch(logoutAndRemoveUserData());
    }
  }, [pathname]);

  useEffect(() => {
    firebase.initializeApp(firebaseConfig).firestore();
    const unsubscribe = firebase
      .auth()
      .onAuthStateChanged(async (_user: any) => {
        const user = _user?.multiFactor.user;

        if (user) {
          // To handle rendering sign up page when refreshing the page
          if (pathname === APP_ROUTES.signUp) {
            dispatch(logoutAndRemoveUserData());
          } else {
            const userProfile = (await getFirstValueFromFirebaseByColKeyVal(
              COLLECTIONS_TYPE.members,
              "uid",
              String(user.uid)
            )) as USER_STATE;

            const clientId = await getClientIdFromDomain();

            if (isUserWithClientId(userProfile?.clientsIds, String(clientId))) {
              dispatch(setUserIsLoggedIn({ isLoggedIn: true }));
              dispatch(
                setUserData({
                  uid: user.uid,
                  email: user.email,
                  firstName: userProfile.firstName,
                  lastName: userProfile.lastName,
                  clientsIds: userProfile.clientsIds,
                  roles: userProfile.roles,
                  createdAt: userProfile.createdAt,
                  profilePictureUrl: userProfile.profilePictureUrl,
                })
              );
              navigate(APP_ROUTES.home);
              return;
            } else {
              toast("Invalid credentials", APP_TOASTIFY.ERROR);
              firebase.auth().signOut();
            }
          }
        }
        dispatch(logoutAndRemoveUserData());
        // navigate(APP_ROUTES.signIn);
        return;
      });

    return () => unsubscribe();
  }, []);

  if (isLoggedIn === undefined) {
    return <AppSpinner />;
  }

  if (isLoggedIn === true) {
    return (
      <StyledAppLoggedInContainer>
        <PWAInstallingInfoModal />
        <AppHeader />
        <Routes>
          <Route
            path={APP_ROUTES.home}
            element={<Navigate to={APP_ROUTES.dashboard} />}
          />
          <Route path={APP_ROUTES.dashboard} element={<DashboardPage />} />
          <Route path={APP_ROUTES.members} element={<MembersPage />} />
          <Route path={APP_ROUTES.templates} element={<TemplatesPage />} />
          <Route path={APP_ROUTES.branding} element={<BrandingPage />} />
        </Routes>
        <AppBottomNavigation />
      </StyledAppLoggedInContainer>
    );
  }

  return (
    <Routes>
      <Route
        path={APP_ROUTES.home}
        element={<Navigate to={APP_ROUTES.signIn} />}
      />
      <Route path={APP_ROUTES.signIn} element={<SignInPage />} />;
      <Route path={APP_ROUTES.signUp} element={<SignUpPage />} />;
    </Routes>
  );
};

export const AppWrapper = () => {
  return (
    <Provider store={store}>
      <MuiThemeProvider>
        <AppThemeProvider>
          <Router>
            <App />
            <ToastContainer />
          </Router>
        </AppThemeProvider>
      </MuiThemeProvider>
    </Provider>
  );
};
