import styled from "styled-components";

export const StyledModalLogoImg = styled.img({
  width: 70,
  height: 70,
});
export const StyledModalContainer = styled.div({
  display: "flex",
  placeContent: "center",
  flexDirection: "column",
  textAlign: "center",
  alignItems: "center",
});
export const StyledInstallingText = styled.div({
  marginTop: 20,
  width: 300,
});
export const StyledInstallingMessageContainer = styled.div({
  marginTop: 34,
  display: "flex",
  flexDirection: "column",
  gap: 16,
  alignItems: "center",
});
export const StyledInstallingGuidance = styled.div({
  display: "flex",
  alignItems: "center",
});
export const StyledAddToHomeScreenContainer = styled.div({
  display: "flex",
  width: 290,
  justifyContent: "space-between",
  background: "#F1F1F1",
  borderRadius: 4,
  height: 30,
  alignItems: "center",
});
