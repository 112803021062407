import React from "react";
import { APP_ROUTES } from "../../../constants/routes.constant";
import { DashboardNavIcon } from "./icons/DashboardNavIcon";
import { MembersNavIcon } from "./icons/MembersNavIcon";
import { TemplatesNavIcon } from "./icons/TemplatesNavIcon";
import { BrandingNavIcon } from "./icons/BrandingNavIcon";

export const NAVIGATION_ITEMS = [
  {
    route: APP_ROUTES.dashboard,
    icon: ($isActive: boolean) => <DashboardNavIcon isActive={$isActive} />,
  },
  {
    route: APP_ROUTES.members,
    icon: ($isActive: boolean) => <MembersNavIcon isActive={$isActive} />,
  },
  {
    route: APP_ROUTES.templates,
    icon: ($isActive: boolean) => <TemplatesNavIcon isActive={$isActive} />,
  },
  {
    route: APP_ROUTES.branding,
    icon: ($isActive: boolean) => <BrandingNavIcon isActive={$isActive} />,
  },
];
