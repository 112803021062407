import React from "react";
import { Typography } from "@mui/material";
import { APP_COMMON_COLORS } from "../../../providers/AppThemeProvider";
import {
  StyledAppPasswordStrengthBarWrapper,
  StyledProgressBar,
  StyledProgressBarWrapper,
  StyledStrengthWrapper,
} from "./AppPasswordStrengthBar.styles";

interface AppPasswordStrengthBarProps {
  password: string;
}

export const AppPasswordStrengthBar = ({
  password,
}: AppPasswordStrengthBarProps) => {
  const calculatePasswordStrength = (password: string) => {
    let strength = 0;
    const criteria = [/[a-z]/, /[A-Z]/, /\d/, /[!@#$%^&*(),.?":{}|<>]/];

    if (password.length >= 8) strength += 20;
    criteria.forEach((pattern) => {
      if (pattern.test(password)) strength += 20;
    });

    return strength;
  };

  const getStrengthLabel = (strength: number) => {
    if (strength <= 40) return "Weak";
    if (strength <= 70) return "Medium";
    return "Strong";
  };

  const getProgressBarColor = (strength: number) => {
    const label = getStrengthLabel(strength);
    return label === "Weak"
      ? "#FF0000"
      : label === "Medium"
      ? "#FFA500"
      : APP_COMMON_COLORS.common.darkGreen;
  };

  const passwordStrength = calculatePasswordStrength(password);
  const strengthLabel = getStrengthLabel(passwordStrength);
  const progressBarColor = password
    ? getProgressBarColor(passwordStrength)
    : APP_COMMON_COLORS.grey[300];

  return (
    <StyledAppPasswordStrengthBarWrapper>
      <StyledProgressBarWrapper>
        <StyledProgressBar
          color={password ? progressBarColor : String(APP_COMMON_COLORS.error)}
          strength="Weak"
        />

        <StyledProgressBar
          color={
            password && strengthLabel !== "Weak"
              ? progressBarColor
              : APP_COMMON_COLORS.grey[300]
          }
          strength="Medium"
        />
        <StyledProgressBar
          color={
            password && strengthLabel === "Strong"
              ? progressBarColor
              : APP_COMMON_COLORS.grey[300]
          }
          strength="Strong"
        />
      </StyledProgressBarWrapper>
      {password ? (
        <StyledStrengthWrapper>
          <Typography fontSize={12}>Strength:</Typography>
          <Typography fontSize={12} fontWeight="900">
            {strengthLabel}
          </Typography>
        </StyledStrengthWrapper>
      ) : (
        <Typography fontSize={12}>Strength</Typography>
      )}
    </StyledAppPasswordStrengthBarWrapper>
  );
};
