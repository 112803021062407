import React, { useState } from "react";
import {
  InputAdornment,
  IconButton,
  Typography,
  useTheme as useMuiTheme,
  useMediaQuery,
  Grid,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik, Form, FormikTouched, FormikErrors } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useTheme } from "styled-components";
import { AppFormikField } from "../../components/core/AppFormik/AppFormikField/AppFormikField";
import { useAutoFill } from "../../hooks/useAutoFill/useAutoFill";
import { useLocation, useNavigate } from "react-router-dom";
import { AppButton } from "../../components/core/AppButton/AppButton";
import { AppleIcon } from "../../assets/icons/AppleIcon";
import { GoogleIcon } from "../../assets/icons/GoogleIcon";
import { VisibilityOffIcon } from "../../assets/icons/VisibilityOffIcon";
import { VisibilityOnIcon } from "../../assets/icons/VisibilityOnIcon";
import { getClientSelector } from "../../redux/client/clientSlice";
import { _fs } from "../../services/firebase/config";
import { AppPasswordStrengthBar } from "../../components/core/AppPasswordStrengthBar/AppPasswordStrengthBar";
import { firebaseHttpsCallable } from "../../hooks/firebase/firebase.helper";
import { FIRESTORE_FUNCTIONS } from "../../constants/firebase.constants";
import { APP_ROUTES } from "../../constants/routes.constant";
import { toast } from "react-toastify";
import { APP_TOASTIFY } from "../../styles/styles.constants";

const schema = Yup.object({
  password: Yup.string()
    .min(8, "Must be 8 characters or more")
    .required("Required"),
});

export const SignUpPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const theme = useTheme();
  const { isAutoFill, autofillInputProps } = useAutoFill();
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const isSmallScreen = useMediaQuery(useMuiTheme().breakpoints.down("lg"));
  const client = useSelector(getClientSelector);

  const {
    branding: { logoUrl, coverImgUrl },
  } = useSelector(getClientSelector);

  const handleOnSubmit = async (values: {
    email: string;
    password: string;
  }) => {
    setIsLoading(true);
    const params = new URLSearchParams(location.search);
    const createdAt = params.get("createdAt");
    const encryptedUID = params.get("c");

    const requestBody = {
      createdAt: createdAt,
      c: encryptedUID,
      p: values.password,
    };

    await firebaseHttpsCallable(FIRESTORE_FUNCTIONS.updateAuthPassword)(
      requestBody
    )
      .then((res: any) => {
        if (res?.data?.result === "ok") {
          navigate(APP_ROUTES.signIn);
        }
      })
      .catch((err: any) => {
        console.log(err);
        toast("Something went wrong", APP_TOASTIFY.ERROR);
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <Grid
      paddingY={0}
      paddingTop={isSmallScreen ? 14 : 0}
      paddingX={!isSmallScreen ? 0 : 1.25}
      sx={{
        backgroundColor: !isSmallScreen
          ? theme.common.white
          : theme.colorPrimary,
        boxSizing: "border-box",
        display: "flex",
        height: !isSmallScreen ? "100dvh" : "auto",
        overflow: !isSmallScreen ? "hidden" : "auto",
        width: "100%",
        justifyContent: "center",
      }}
    >
      {!isSmallScreen && (
        <Grid
          item
          display="flex"
          alignItems="center"
          md={6}
          sx={{
            width: "50%",
            height: "auto",
          }}
        >
          {!coverImgUrl ? (
            <Box
              display={"flex"}
              flexDirection={"column"}
              width={"100%"}
              height={"100%"}
              justifyContent={"center"}
              paddingX={5}
              sx={{
                background: theme.colorPrimary,
              }}
            >
              <Box
                display={"flex"}
                height={"100%"}
                justifyContent={"center"}
                flexDirection={"column"}
              >
                <Typography
                  mb={2.5}
                  fontWeight={400}
                  variant="h6"
                  color={theme.common.white}
                >
                  WELCOME TO
                </Typography>
                <Typography
                  mb={2.5}
                  fontWeight={700}
                  fontSize={"2rem"}
                  color={theme.common.white}
                >
                  {client.name} Football Academy
                </Typography>
              </Box>
              <Box mb={"87px"} width={"100%"} alignSelf={"end"}>
                <Typography
                  fontWeight={300}
                  color={theme.common.white}
                  variant="body2"
                >
                  Powered by YourZown
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box>
              <img
                alt="Club Cover"
                src={coverImgUrl}
                width={"100%"}
                height={"100%"}
              />
            </Box>
          )}
        </Grid>
      )}
      <Grid
        item
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent={isSmallScreen ? "start" : "center"}
        borderRadius={isSmallScreen ? "10px 10px 0px 0px" : "10px"}
        md={6}
        xs={12}
        sx={{
          background: !isSmallScreen
            ? theme.common.white
            : theme.colorSecondary,
        }}
        paddingX={0.625}
        paddingTop={isSmallScreen ? 5 : 1}
        paddingBottom={!isSmallScreen ? 1 : 0}
        width={isSmallScreen ? "440px" : "50%"}
        height={"auto"}
      >
        {isSmallScreen && (
          <>
            <Typography
              marginBottom={1.375}
              color={theme.common.white}
              fontWeight={"300"}
              variant="caption"
            >
              WELCOME, John
            </Typography>
          </>
        )}

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent={isSmallScreen ? "flex-start" : "center"}
          paddingTop={isSmallScreen ? 3.75 : 0}
          borderRadius={isSmallScreen ? "10px 10px 0px 0px" : "10px"}
          width={1}
          sx={{
            backgroundColor: theme.common.white,
            height: "660px",
          }}
        >
          <Formik
            enableReinitialize={true}
            initialValues={{ email: "", password: "" }}
            validationSchema={schema}
            onSubmit={(values: { email: string; password: string }) =>
              handleOnSubmit(values)
            }
          >
            {({
              errors,
              values,
              touched,
              handleChange,
              handleBlur,
              isSubmitting,
            }) => {
              const _touched: FormikTouched<{
                email: string;
                password: string;
              }> = touched;

              const _errors: FormikErrors<{ email: string; password: string }> =
                errors;

              return (
                <Form
                  style={{
                    width: isSmallScreen ? "100%" : 400,
                  }}
                >
                  <Box paddingX={2.5}>
                    <Box display="flex" justifyContent="center">
                      <img
                        width={"113px"}
                        height={"113px"}
                        src={logoUrl}
                        alt="Football Club Logo"
                      />
                    </Box>
                    {!isSmallScreen && (
                      <Typography
                        textAlign={"center"}
                        color={theme.common.black}
                        fontWeight={600}
                        variant="h6"
                        marginTop={1}
                        marginBottom={4.5}
                      >
                        Sign up
                      </Typography>
                    )}
                    <AppFormikField
                      name="password"
                      value={values.password}
                      label="Choose password"
                      placeholder="Password"
                      errorMessage={
                        _touched.password && _errors.password
                          ? String(errors.password)
                          : undefined
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type={showPassword ? "text" : "password"}
                      InputLabelProps={{ shrink: isAutoFill || undefined }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                              sx={{
                                marginRight: "2px",
                              }}
                            >
                              {showPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityOnIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        marginTop: 2,
                      }}
                    />
                    <Box marginTop={1.25} marginBottom={2} marginLeft={2}>
                      <AppPasswordStrengthBar password={values.password} />
                    </Box>
                    <AppButton isLoading={isLoading} type="submit">
                      <Typography
                        color={theme.common.white}
                        fontWeight={600}
                        variant="body1"
                      >
                        SIGN UP
                      </Typography>
                    </AppButton>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      my={2.75}
                    >
                      <Box
                        flexGrow={1}
                        borderBottom={1}
                        borderColor={theme.grey[600]}
                        mx={2}
                      />
                      <Typography
                        fontWeight={600}
                        color={theme.common.black}
                        variant="body2"
                      >
                        Or continue with
                      </Typography>
                      <Box
                        flexGrow={1}
                        borderBottom={1}
                        borderColor={theme.grey[600]}
                        mx={2}
                      />
                    </Box>
                    <Box
                      m={2}
                      display={"flex"}
                      gap={isSmallScreen ? 2.625 : 5}
                      justifyContent={"center"}
                    >
                      <IconButton
                        sx={{
                          borderRadius: "50%",
                          padding: 2,
                          boxShadow: "0px 5px 20px 0px #00000033",
                        }}
                      >
                        <GoogleIcon />
                      </IconButton>
                      <IconButton
                        sx={{
                          borderRadius: "50%",
                          padding: 2,
                          boxShadow: "0px 5px 20px 0px #00000033",
                        }}
                      >
                        <AppleIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Grid>
    </Grid>
  );
};
