import { DashIcon } from "../../core/AppBurgerMenu/icons/DashIcon";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { StyledInstallingGuidance } from "./PWAInstallingInfoModal.styles";

export const AndroidView = () => {
  return (
    <>
      <DashIcon />
      <StyledInstallingGuidance>
        Click the
        <MoreVertIcon style={{ fontSize: 25 }} />3 dots icon above
      </StyledInstallingGuidance>
      <DashIcon />
      <div>Select Add to Home screen</div>
      <DashIcon />
      <div>
        <div style={{ marginBottom: 12 }}>A popup will appear.</div>
        <div>Tap Add to save the app to your phone</div>
      </div>
    </>
  );
};
