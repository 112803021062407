import { Avatar } from "@mui/material";
import { APP_ROUTES } from "../../../constants/routes.constant";
import { DashboardIcon } from "./icons/DashboardIcon";
import { MembersIcon } from "./icons/MembersIcon";
import { TemplatesIcon } from "./icons/TemplatesIcon";
import { BrandingIcon } from "./icons/BrandingIcon";

export const APP_LINKS = [
  {
    url: APP_ROUTES.dashboard,
    label: "Dashboard",
    icon: ($isActive: boolean) => <DashboardIcon isActive={$isActive} />,
  },
  {
    url: APP_ROUTES.members,
    label: "Members",
    icon: ($isActive: boolean) => <MembersIcon isActive={$isActive} />,
  },
  {
    url: APP_ROUTES.templates,
    label: "Templates",
    icon: ($isActive: boolean) => <TemplatesIcon isActive={$isActive} />,
  },
  {
    url: APP_ROUTES.branding,
    label: "Branding",
    icon: ($isActive: boolean) => <BrandingIcon isActive={$isActive} />,
  },
];
