import React from "react";
import { AppBurgerMenu } from "../AppBurgerMenu/AppBurgerMenu";
import {
  StyledAppHeaderContainer,
  StyledAppBurgerMenuContainer,
  StyledAppHeaderWrapper,
  StyledLogoContainer,
  StyledLogoImg,
  StyledAvatarContainer,
  StyledGreetingMessageContainer,
} from "./AppHeader.styles";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../../redux/client/clientSlice";
import { GreetingMessage } from "./GreetingMessage/GreetingMessage";
import { ProfileAvatar } from "../../shared/ProfileAvatar/ProfileAvatar";

export const AppHeader = () => {
  const client = useSelector(getClientSelector);

  return (
    <StyledAppHeaderWrapper>
      <StyledAppHeaderContainer>
        <StyledGreetingMessageContainer>
          <GreetingMessage />
        </StyledGreetingMessageContainer>
        <StyledLogoContainer>
          <StyledLogoImg src={client?.branding.logoUrl} alt="logo" />
        </StyledLogoContainer>

        <StyledAppBurgerMenuContainer>
          <AppBurgerMenu />
        </StyledAppBurgerMenuContainer>

        <StyledAvatarContainer>
          <ProfileAvatar />
        </StyledAvatarContainer>
      </StyledAppHeaderContainer>
    </StyledAppHeaderWrapper>
  );
};
