import React from "react";
import { APP_COMMON_COLORS } from "../../../../providers/AppThemeProvider";

export const DashboardIcon = (props: { isActive: boolean }) => {
  const color = props.isActive
    ? APP_COMMON_COLORS.common.white
    : APP_COMMON_COLORS.dark[500];

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.16667 8.33333C6.46785 8.33333 8.33333 6.46785 8.33333 4.16667C8.33333 1.86548 6.46785 0 4.16667 0C1.86548 0 0 1.86548 0 4.16667C0 6.46785 1.86548 8.33333 4.16667 8.33333Z"
        fill={color}
      />
      <path
        d="M15.8337 8.33333C18.1348 8.33333 20.0003 6.46785 20.0003 4.16667C20.0003 1.86548 18.1348 0 15.8337 0C13.5325 0 11.667 1.86548 11.667 4.16667C11.667 6.46785 13.5325 8.33333 15.8337 8.33333Z"
        fill={color}
      />
      <path
        d="M4.16667 20.0003C6.46785 20.0003 8.33333 18.1348 8.33333 15.8337C8.33333 13.5325 6.46785 11.667 4.16667 11.667C1.86548 11.667 0 13.5325 0 15.8337C0 18.1348 1.86548 20.0003 4.16667 20.0003Z"
        fill={color}
      />
      <path
        d="M15.8337 20.0003C18.1348 20.0003 20.0003 18.1348 20.0003 15.8337C20.0003 13.5325 18.1348 11.667 15.8337 11.667C13.5325 11.667 11.667 13.5325 11.667 15.8337C11.667 18.1348 13.5325 20.0003 15.8337 20.0003Z"
        fill={color}
      />
    </svg>
  );
};
