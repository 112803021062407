export const DashIcon = () => {
  return (
    <svg
      width="329"
      height="1"
      viewBox="0 0 329 1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        y1="0.5"
        x2="329"
        y2="0.5"
        stroke="#999999"
        stroke-dasharray="4 4"
      />
    </svg>
  );
};
