import React from "react";
import { Box, FormGroup, Typography, useTheme } from "@mui/material";
import { Form, Formik, FormikErrors, FormikTouched } from "formik";
import { TabPanel } from "../../TabPanel/TabPanel";
import { MemberFormValues } from "../../MembersPage.types";
import { AppFormikField } from "../../../../components/core/AppFormik/AppFormikField/AppFormikField";
import { AppSwitch } from "../../../../components/core/AppSwitch/AppSwitch";
import { AppButton } from "../../../../components/core/AppButton/AppButton";
import { addMemberYupSchema } from "../../constants";

interface AddMemberTabProps {
  outerTabValue: number;
  addMemberTabs: string[];
  isLgScreen: boolean;
  handleOuterTabChange: (
    event: React.SyntheticEvent<Element, Event> | null,
    newValue: number
  ) => void;
  setAddMemebrsformValues: React.Dispatch<
    React.SetStateAction<MemberFormValues>
  >;
  isAutoFill: boolean;
  autofillInputProps: {
    onAnimationStart: (e: React.AnimationEvent<HTMLDivElement>) => void;
    onAnimationEnd: (e: React.AnimationEvent<HTMLDivElement>) => void;
    onFocus: () => void;
  };
  hasError: (e: boolean) => void;
}

export const AddMemberTab = ({
  addMemberTabs,
  autofillInputProps,
  handleOuterTabChange,
  isAutoFill,
  isLgScreen,
  outerTabValue,
  setAddMemebrsformValues,
  hasError,
}: AddMemberTabProps) => {
  const theme = useTheme();

  return (
    <TabPanel value={outerTabValue} index={0}>
      {!isLgScreen && (
        <Typography
          color={theme.palette.secondary.contrastText}
          fontWeight={400}
          variant="caption"
          textAlign={"center"}
        >
          STEP {outerTabValue + 1} OF {addMemberTabs.length}
        </Typography>
      )}
      <Typography
        mb={isLgScreen ? 9 : 7}
        color={theme.palette.common.black}
        fontWeight={600}
        variant="h4"
        textAlign={isLgScreen ? "left" : "center"}
      >
        Member profile
      </Typography>
      <Formik
        enableReinitialize={true}
        validateOnMount
        initialValues={{ email: "", firstName: "", lastName: "" }}
        validationSchema={addMemberYupSchema}
        onSubmit={async (values: MemberFormValues) =>
          handleOuterTabChange(null, 1)
        }
      >
        {({ errors, values, touched, handleChange, handleBlur, isValid }) => {
          const _touched: FormikTouched<MemberFormValues> = touched;

          const _errors: FormikErrors<MemberFormValues> = errors;
          const formHasErrors: boolean =
            Object.keys(_errors).length > 0 || !isValid;
          hasError(formHasErrors);

          return (
            <Form style={{ height: "100%" }}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                height={"100%"}
                flexGrow={1}
                gap={5}
              >
                <Box display={"flex"} flexDirection={"column"} gap={"20px"}>
                  <AppFormikField
                    name="email"
                    value={values.email}
                    label="EMAIL ADDRESS"
                    placeholder="Email Address"
                    InputLabelProps={{
                      shrink: isAutoFill || undefined,
                    }}
                    InputProps={autofillInputProps}
                    errorMessage={
                      _touched.email && _errors.email
                        ? String(errors.email)
                        : undefined
                    }
                    onBlur={handleBlur}
                    onChange={(e: any) => {
                      handleChange(e);
                      setAddMemebrsformValues({
                        ...values,
                        email: e.target.value,
                      });
                    }}
                    type="text"
                  />

                  <AppFormikField
                    name="firstName"
                    value={values.firstName}
                    label="First Name"
                    placeholder="firstName"
                    errorMessage={
                      _touched.firstName && _errors.firstName
                        ? String(errors.firstName)
                        : undefined
                    }
                    onBlur={handleBlur}
                    onChange={(e: any) => {
                      handleChange(e);
                      setAddMemebrsformValues({
                        ...values,
                        firstName: e.target.value,
                      });
                    }}
                    InputLabelProps={{
                      shrink: isAutoFill || undefined,
                    }}
                  />
                  <AppFormikField
                    name="lastName"
                    value={values.lastName}
                    label="Last Name"
                    placeholder="lastName"
                    errorMessage={
                      _touched.lastName && _errors.lastName
                        ? String(errors.lastName)
                        : undefined
                    }
                    onBlur={handleBlur}
                    onChange={(e: any) => {
                      handleChange(e);
                      setAddMemebrsformValues({
                        ...values,
                        lastName: e.target.value,
                      });
                    }}
                    InputLabelProps={{
                      shrink: isAutoFill || undefined,
                    }}
                  />
                </Box>
                <FormGroup sx={{ width: "100%" }}>
                  <Box marginBottom={5} display="flex" alignItems="center">
                    <AppSwitch />
                    <Typography
                      marginLeft={"2px"}
                      variant="body2"
                      color={theme.palette.primary.light}
                      fontWeight={400}
                    >
                      Assign as administrator
                    </Typography>
                  </Box>
                  <AppButton type="submit">
                    <Typography
                      color={theme.palette.common.white}
                      fontWeight={600}
                      variant="body1"
                    >
                      Continue
                    </Typography>
                  </AppButton>
                </FormGroup>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </TabPanel>
  );
};
