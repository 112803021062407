import { ReactElement, ReactNode } from "react";
import styled, { CSSObject, DefaultTheme, useTheme } from "styled-components";
import { Button, CircularProgress, SxProps, Theme } from "@mui/material";

export interface AppButtonProps {
  isDisabled?: boolean;
  onClick?: (e: any) => any;
  children: ReactNode;
  isLoading?: boolean;
  customStyles?: CSSObject;
  buttonLevel?: "primary" | "secondary";
  type?: "submit" | "reset";
  sx?: SxProps<Theme>;
}

const getSecondaryButtonStyle = (theme: DefaultTheme) => ({
  color: theme.nuetralGrey300,
  background: theme.primaryBlack400,
  border: `1px solid ${theme.primaryBlack300}`,
  "&:hover": {
    background: theme.primaryBlack400,
    border: `1px solid ${theme.white}`,
  },
});

const StyledButton = styled(Button)<{ $customStyles: CSSObject }>(
  ({ $customStyles, theme }) => ({
    "&&": {
      background: theme.colorPrimary,
      height: 48,
      borderRadius: 24,
      width: "100%",
      border: `1px solid ${theme.colorPrimary}`,
      color: theme.common.white,
      textTransform: "none",
      fontWeight: 600,
      boxShadow: "0px 5px 20px 0px #00000033",
      "&:active": {
        transform: "scale(0.994)",
      },
      "&:hover": {
        background: theme.colorPrimary,
        border: `1px solid ${theme.white}`,
      },
      ...$customStyles,
    },
    "&:disabled": {
      background: theme.grey[300],
      border: `1px solid ${theme.grey[300]}`,
    },
  })
);

export const AppButton = (props: AppButtonProps): ReactElement => {
  const theme = useTheme();
  return (
    <div style={{ borderRadius: 24 }}>
      <StyledButton
        onClick={
          props.isDisabled || props.isLoading ? undefined : props.onClick
        }
        tabIndex={0}
        $customStyles={{
          ...(props.isDisabled && { opacity: 0.5 }),
          ...(props.buttonLevel === "secondary" && {
            ...getSecondaryButtonStyle(theme),
          }),
          ...props.customStyles,
        }}
        type={props.type}
        disabled={props.isDisabled || props.isLoading}
        sx={props?.sx}
      >
        {props.isLoading ? <CircularProgress size={30} /> : props.children}
      </StyledButton>
    </div>
  );
};
