import { useTheme } from "@mui/material";

export const CloseIcon = () => {
  const theme = useTheme();
  return (
    <svg
      width="13"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_556_48385"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="21"
        height="20"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.84986 19.6017L10.4987 12.1108L18.1501 19.6017C18.6444 20.0857 19.4104 20.1294 19.9536 19.7283L20.0845 19.6188L20.0948 19.6089C20.6223 19.0994 20.6366 18.2589 20.1268 17.7317L12.6523 10.0015L20.0799 2.25751C20.5853 1.73055 20.5723 0.895164 20.0506 0.384235C19.5276 -0.128065 18.6906 -0.128084 18.1675 0.384205L10.5 7.89426L2.83244 0.384205C2.30936 -0.128084 1.47241 -0.128065 0.949358 0.384235C0.427708 0.895155 0.414658 1.73055 0.920088 2.25751L8.34772 10.0015L0.873148 17.7317C0.363368 18.2589 0.377708 19.0994 0.905178 19.6089L0.915508 19.6188C1.46009 20.1336 2.31445 20.1261 2.84986 19.6017Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_556_48385)">
        <mask
          id="mask1_556_48385"
          maskUnits="userSpaceOnUse"
          x="-424"
          y="-57"
          width="470"
          height="1016"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M-423.363 -56.2353H45.5098V958.541H-423.363V-56.2353Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_556_48385)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M-5.75098 26.248H26.7523V-6.24918H-5.75098L-5.75098 26.248Z"
            fill={theme.palette.primary.main}
          />
        </g>
      </g>
    </svg>
  );
};
