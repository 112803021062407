import { useTheme } from "@mui/material";

export const FilterIcon = () => {
  const theme = useTheme();
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        opacity="0.1"
        cx="15"
        cy="15"
        r="15"
        fill={theme.palette.primary.main}
      />
      <rect
        x="8"
        y="11"
        width="14"
        height="2"
        rx="1"
        fill={theme.palette.primary.main}
      />
      <rect
        x="11"
        y="15"
        width="8"
        height="2"
        rx="1"
        fill={theme.palette.primary.main}
      />
      <rect
        x="13"
        y="19"
        width="4"
        height="2"
        rx="1"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};
