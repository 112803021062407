export enum COLLECTIONS_TYPE {
  colors = "colors",
  clients = "clients",
  members = "members",
}

export enum FIRESTORE_FUNCTIONS {
  subscribeToTopic = "functions-notifications-subscribeToTopic",
  getRolesAndGroups = "functions-client-getRolesAndGroups",
  sendInvite = "functions-member-inviteMember",
  updateAuthPassword = "functions-auth-updatePassword",
}
