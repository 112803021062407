import { createSlice } from "@reduxjs/toolkit";
import { GlobalState } from "../../types/global";
import { CLIENT_STATE } from "../../types/client/client.types";

const initialState = {
  branding: {},
} as CLIENT_STATE;

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    setClient(state, action: { payload: CLIENT_STATE }) {
      state.branding.logoUrl = action.payload.branding.logoUrl;
      state.branding.coverImgUrl = action.payload.branding.coverImgUrl;
      state.branding.colorPrimary = action.payload.branding.colorPrimary;
      state.branding.colorSecondary = action.payload.branding.colorSecondary;
      state.branding.welcomeMessage = action.payload.branding.welcomeMessage;
      state.clientId = action.payload.clientId;
      state.domain = action.payload.domain;
      state.name = action.payload.name;
      state.emailSignature = action.payload.emailSignature;
    },
  },
});

export const { setClient } = clientSlice.actions;

export const getClientSelector = (state: GlobalState) => state.client || {};
