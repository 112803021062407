import styled, { CSSObject } from "styled-components";
import { StyledAppFormikFieldError } from "../AppFormik.styles";
import { StyledTextField } from "./AppFormikField.styles";
import { ChangeEventHandler } from "react";
import {
  FilledInputProps,
  InputBaseComponentProps,
  InputLabelProps,
  SxProps,
  Theme,
} from "@mui/material";

interface AppFormikFieldProps {
  name?: string;
  type?: "text" | "number" | "email" | "password";
  label?: string;
  placeholder?: string;
  fieldStyle?: CSSObject;
  fieldEndContent?: string | React.ReactNode;
  containerstyle?: CSSObject;
  errorMessage?: string;
  isRequired?: boolean;
  value?: string | number;
  onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  isDisabled?: boolean;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onFocus?: () => void;
  InputProps?: Partial<FilledInputProps>;
  sx?: SxProps<Theme>;
  InputLabelProps?: Partial<InputLabelProps>;
  inputProps?: InputBaseComponentProps;
}

const StyledAppFormikFieldContainer = styled.div<{ $containerstyle?: {} }>(
  ({ $containerstyle }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    ...$containerstyle,
  })
);

const StyledFieldContainer = styled.div<{}>(() => ({
  position: "relative",
}));

export const AppFormikField = (props: AppFormikFieldProps) => {
  return (
    <StyledAppFormikFieldContainer
      $containerstyle={{ ...props.containerstyle }}
    >
      <StyledFieldContainer>
        <StyledTextField
          $styledErrorProp={Boolean(props.errorMessage)}
          name={props.name}
          label={props.label}
          type={props.type}
          placeholder={props.placeholder}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          onChange={props.onChange}
          value={props.value}
          InputProps={props.InputProps}
          inputProps={props.inputProps}
          InputLabelProps={props.InputLabelProps}
          sx={props.sx}
        />
      </StyledFieldContainer>
      <StyledAppFormikFieldError>
        {props.errorMessage}
      </StyledAppFormikFieldError>
    </StyledAppFormikFieldContainer>
  );
};
