import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormGroup,
  InputAdornment,
  OutlinedInput,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import { TabPanel } from "../../TabPanel/TabPanel";
import { AppSwitch } from "../../../../components/core/AppSwitch/AppSwitch";
import { FilterIcon } from "../../../../components/core/AppBurgerMenu/icons/FilterIcon";
import { CheckBoxIconNotChecked } from "../../../../components/core/AppBurgerMenu/icons/CheckBoxIconNotChecked";
import { CheckBoxIcon } from "../../../../components/core/AppBurgerMenu/icons/CheckBoxIcon";
import { AppButton } from "../../../../components/core/AppButton/AppButton";
import {
  GetRolesAndGroupsReq,
  GetRolesAndGroupsRes,
  MemberFormValues,
  RolesAndGroups,
} from "../../MembersPage.types";
import {
  StyledBox,
  StyledCheckbox,
  StyledDivider,
  StyledList,
} from "./GroupsTab.styles";
import { useEffect, useState } from "react";
import { firebaseHttpsCallable } from "../../../../hooks/firebase/firebase.helper";
import { FIRESTORE_FUNCTIONS } from "../../../../constants/firebase.constants";
import { getClientSelector } from "../../../../redux/client/clientSlice";
import { useSelector } from "react-redux";
import { AppSpinner } from "../../../../components/core/AppSpinner/AppSpinner";
import { toast } from "react-toastify";

interface GroupsTabTabProps {
  outerTabValue: number;
  addMemberTabs: string[];
  isLgScreen: boolean;
  innerTabsValue: number;
  handleInnerTabChange: (
    _: React.SyntheticEvent<Element, Event> | null,
    newValue: number
  ) => void;
  rolesAndGroups: RolesAndGroups;
  isAddToAllChecked: boolean[];
  toggleCheckBox: (
    _: React.SyntheticEvent<Element, Event> | null,
    role: string,
    index: number
  ) => void;
  handleAddToAll: (role: string, checkAll: boolean) => void;
  setRolesAndGroups: React.Dispatch<React.SetStateAction<RolesAndGroups>>;
  addMemberFormValues: MemberFormValues;
  setIsModalClose: () => void;
}

export const GroupsTab = ({
  addMemberTabs,
  isLgScreen,
  outerTabValue,
  innerTabsValue,
  handleInnerTabChange,
  rolesAndGroups,
  isAddToAllChecked,
  toggleCheckBox,
  handleAddToAll,
  setRolesAndGroups,
  addMemberFormValues,
  setIsModalClose,
}: GroupsTabTabProps) => {
  const theme = useTheme();
  const client = useSelector(getClientSelector);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      client.clientId &&
      outerTabValue === 1 &&
      !Object.keys(rolesAndGroups).length
    ) {
      (async () => {
        try {
          setIsLoading(true);
          const { data } = await firebaseHttpsCallable<
            GetRolesAndGroupsReq,
            GetRolesAndGroupsRes
          >(FIRESTORE_FUNCTIONS.getRolesAndGroups)({
            clientId: client.clientId,
          });

          setRolesAndGroups({
            ...data?.data?.roles?.reduce((a, b) => {
              return { ...a, [b]: data?.data.groups };
            }, {}),
          });
          console.log({ data });
        } catch (error) {
          console.log({ error });
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [client.clientId, outerTabValue]);

  const { branding, clientId, domain, name, emailSignature } =
    useSelector(getClientSelector);

  const errorToast = (errMsg: string) =>
    toast(errMsg, {
      hideProgressBar: true,
      type: "error",
      icon: false,
      style: {
        background: theme.palette.error.main,
        color: "white",
      },
    });
  const successToast = (successMsg: string) =>
    toast(successMsg, {
      hideProgressBar: true,
      type: "success",
      icon: false,
      style: {
        background: theme.palette.success.light,
        color: "white",
      },
    });

  const sendInvite = async () => {
    setIsLoading(true);

    const rolesAndGroupsBody: any = Object.fromEntries(
      Object.entries(rolesAndGroups).map(([role, items]) => [
        role,
        Object.fromEntries(
          items
            .filter((item) => item.isChecked)
            .map((item) => [item.groupId, true])
        ),
      ])
    );
    let rolesAndGroupsBodyHasValues = false;

    for (const key in rolesAndGroupsBody) {
      if (Object.keys(rolesAndGroupsBody[key]).length > 0) {
        rolesAndGroupsBodyHasValues = true;
        break;
      }
    }
    if (!rolesAndGroupsBodyHasValues) {
      errorToast("Roles are required");
      setIsLoading(false);
      return;
    }
    const responseBody = {
      ...addMemberFormValues,
      roles: rolesAndGroupsBody,
      client: {
        branding,
        clientId,
        domain,
        emailSignature,
        name,
      },
    };
    await firebaseHttpsCallable(FIRESTORE_FUNCTIONS.sendInvite)(responseBody)
      .then((res: any) => {
        if (res?.data?.result === "nok") {
          errorToast("Something went wrong");
        } else {
          successToast("Invite Sent Successfully!");
          setIsModalClose();
        }
      })
      .catch((err: any) => {
        errorToast("Something went wrong");
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading)
    return (
      <CircularProgress
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          top: "50%",
          left: "50%",
          position: "absolute",
        }}
      />
    );

  return (
    <TabPanel value={outerTabValue} index={1}>
      {!isLgScreen && (
        <Typography
          color={theme.palette.secondary.contrastText}
          fontWeight={400}
          variant="caption"
          textAlign={"center"}
        >
          STEP {outerTabValue + 1} OF {addMemberTabs.length}
        </Typography>
      )}
      <Typography
        textAlign={isLgScreen ? "start" : "center"}
        variant="h5"
        mb={6}
        fontWeight={600}
      >
        Select role & group
      </Typography>
      <Box
        sx={{
          maxWidth: { xs: 320, sm: "100%" },
        }}
      >
        <Tabs
          sx={{
            paddingY: 2.5,
            "& .MuiTabs-flexContainer": {
              gap: 1.25,
              // marginX: 3,
            },
            "& button": {
              borderRadius: 1,
              background: theme.palette.common.white,
              fontWeight: 500,
              fontSize: "12px",

              border: `1px solid ${theme.palette.grey[100]}`,
              maxWidth: 90,
              height: 40,
              boxShadow: "0px 5px 7px 0px #0000001A",
              color: theme.palette.primary.main,
            },
            "& .Mui-selected": {
              background: theme.palette.primary.main,
              color: `${theme.palette.common.white} !important`,
              boxShadow: "none",
            },
            "& .MuiTabs-indicator": {
              backgroundColor: "transparent",
            },
          }}
          value={innerTabsValue}
          onChange={handleInnerTabChange}
          variant="scrollable"
          aria-label="scrollable auto tabs example"
        >
          {Object.keys(rolesAndGroups ?? {}).map((role: string) => (
            <Tab key={role} label={role} />
          ))}
        </Tabs>
      </Box>
      <Divider
        sx={{
          width: "100%",
          borderRadius: 2,
          border: `1px solid ${theme.palette.secondary.light}`,
          borderColor: "divider",
          marginBottom: 2.5,
        }}
      />

      {Object.keys(rolesAndGroups ?? {}).map(
        (role: string, index: number) =>
          index === innerTabsValue && (
            <TabPanel key={role} value={innerTabsValue} index={index}>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                marginBottom={3}
              >
                <FormGroup>
                  <Box display="flex" alignItems="center">
                    <AppSwitch
                      onChange={(e) => handleAddToAll(role, e.target.checked)}
                      checked={isAddToAllChecked[innerTabsValue]}
                    />
                    <Typography
                      marginLeft={"2px"}
                      variant="body2"
                      color={theme.palette.secondary.contrastText}
                      fontWeight={500}
                      marginRight={6.5}
                    >
                      Add to all
                    </Typography>
                  </Box>
                </FormGroup>
                <FormControl
                  sx={{
                    width: "150px",
                  }}
                  variant="outlined"
                >
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    sx={{
                      borderRadius: 25,
                      height: "40px",
                      paddingRight: "8px",
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <FilterIcon />
                      </InputAdornment>
                    }
                    placeholder="Filter groups"
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                </FormControl>
              </Box>
              <Divider
                sx={{
                  width: "100%",
                  borderRadius: 2,
                  border: `1px solid ${theme.palette.secondary.light}`,
                  borderColor: "divider",
                  marginBottom: 2.5,
                }}
              />
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"space-between"}
                height={"100%"}
              >
                <Box mb={isLgScreen ? 8 : 7}>
                  <StyledBox>
                    <StyledList>
                      {rolesAndGroups?.[role]?.map(
                        (checkbox: any, index: any) => (
                          <Box key={index}>
                            <Box
                              mt={index === 0 ? 0 : 3}
                              mb={1.5}
                              display="flex"
                              alignItems="center"
                            >
                              <StyledCheckbox
                                checked={
                                  checkbox.isChecked === undefined
                                    ? false
                                    : checkbox.isChecked
                                }
                                size="medium"
                                icon={<CheckBoxIconNotChecked />}
                                checkedIcon={<CheckBoxIcon />}
                                onChange={(e) => toggleCheckBox(e, role, index)}
                              />
                              <Typography fontWeight={600} variant="body1">
                                {checkbox.groupName}
                              </Typography>
                            </Box>
                            {index !== rolesAndGroups?.[role]?.length - 1 && (
                              <StyledDivider />
                            )}
                          </Box>
                        )
                      )}
                    </StyledList>
                  </StyledBox>
                </Box>

                <AppButton
                  onClick={sendInvite}
                  type="submit"
                  sx={{ maxWidth: "400px" }}
                >
                  <Typography
                    color={theme.palette.common.white}
                    fontWeight={600}
                    variant="body1"
                  >
                    Send Invite
                  </Typography>
                </AppButton>
              </Box>
            </TabPanel>
          )
      )}
    </TabPanel>
  );
};
