import React from "react";

import { useSelector } from "react-redux";
import { getClientSelector } from "../../../redux/client/clientSlice";
import {
  StyledInstallingMessageContainer,
  StyledInstallingText,
  StyledModalContainer,
  StyledModalLogoImg,
} from "./PWAInstallingInfoModal.styles";
import { detectUserMobile } from "../../../helpers/detectUserMobile";
import { MOBILE_TYPES } from "../../../types/mobile.types";
import { IphoneView } from "./IphoneView";
import { AndroidView } from "./AndroidView";
import { AppModal } from "../../core/AppModal/AppModal";
import { useState } from "react";
import { useScreenWidth } from "../../../hooks/useScreenWidth/useScreenWidth";
import { isRunningAsPWA } from "../../../helpers/isRunningAsPWA";

export const PWAInstallingInfoModal = () => {
  const mobileType: MOBILE_TYPES = detectUserMobile();
  const client = useSelector(getClientSelector);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const deviceShowingModal = localStorage.getItem(
    "PWAInstallingInfoModalOpened"
  );
  const screenWidth = useScreenWidth();

  const handleCloseModal = () => {
    localStorage.setItem("PWAInstallingInfoModalOpened", Date.now().toString());
    setIsOpen(false);
  };

  if (isRunningAsPWA()) {
    return <></>;
  }

  if (mobileType.unknown || deviceShowingModal || screenWidth >= 1200) {
    return <></>;
  }

  return (
    <AppModal isOpen={isOpen} closeModal={handleCloseModal}>
      <StyledModalContainer>
        <StyledModalLogoImg src={client?.branding.logoUrl} alt="logo" />
        <StyledInstallingText>
          For the best experience we recommend installing the {client.name}
          Football Academy app to your home screen!
        </StyledInstallingText>
        <StyledInstallingMessageContainer>
          {mobileType.iphone && <IphoneView />}
          {mobileType.android && <AndroidView />}
        </StyledInstallingMessageContainer>
      </StyledModalContainer>
    </AppModal>
  );
};
