import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
import { firebaseHttpsCallable } from "./firebase.helper";
import { FIRESTORE_FUNCTIONS } from "../../constants/firebase.constants";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../redux/client/clientSlice";
import { getUser } from "../../redux/user/userSlice";
import { GENERAL_REPONSE } from "../../types/firbase.types";

const setSubscribedToInitialTopicsLocatlStorage = () => {
  localStorage.setItem("subscribedToInitialTopics", Date.now().toString());
};

export const useSubsrcibeToInitialTopics = () => {
  const [tokenErrorTrials, setTokenErrorTrials] = useState(0);

  const client = useSelector(getClientSelector);
  const user = useSelector(getUser);
  const notificationPermission = localStorage.getItem("notificationPermission");
  const subscribedToInitialTopics = localStorage.getItem(
    "subscribedToInitialTopics"
  );

  useEffect(() => {
    const canSubscribeToTopicsCondition = Boolean(
      client.clientId &&
        user.uid &&
        notificationPermission === "granted" &&
        !subscribedToInitialTopics
    );

    if (canSubscribeToTopicsCondition) {
      const groups = ["all", client.clientId, user.uid];

      navigator.serviceWorker.addEventListener("message", (e) => {
        messageEventListener(messaging, e);
      });

      const messageEventListener = (messaging: any, e: MessageEvent) => {
        console.log("Received message from service worker:", e.data);
      };

      const messaging = firebase.messaging();

      messaging
        .getToken()
        .then((token) => {
          console.log(token);
          firebaseHttpsCallable(FIRESTORE_FUNCTIONS.subscribeToTopic)({
            token: token,
            topics: groups,
          }).then((res) => {
            const data = res.data as GENERAL_REPONSE;
            if (data.result === "ok") {
              setSubscribedToInitialTopicsLocatlStorage();
            }
          });
        })
        .catch((error) => {
          console.log(error);
          setTokenErrorTrials(Number(tokenErrorTrials) + 1);
        });

      if (tokenErrorTrials > 1 || tokenErrorTrials < 7) {
        setTimeout(() => {
          messaging
            .getToken()
            .then((token) => {
              firebaseHttpsCallable(FIRESTORE_FUNCTIONS.subscribeToTopic)({
                token: token,
                topics: groups,
              }).then((res) => {
                const data = res.data as GENERAL_REPONSE;
                if (data.result === "ok") {
                  setSubscribedToInitialTopicsLocatlStorage();
                }
              });
            })
            .catch((error) => {
              console.log(error);
              setTokenErrorTrials(Number(tokenErrorTrials) + 1);
            });
        }, 5000);
      }

      const unsubscribe = messaging.onMessage((payload) => {
        console.log("Message received. ", payload);
      });

      return () => {
        unsubscribe();
      };
    }
  }, [client.clientId, user.uid, tokenErrorTrials]);
};
